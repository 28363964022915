import './App.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout, setRefresh } from './reducer/authentication';
import { cleanup } from '@testing-library/react';
import './fonts/font.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './Router';
import axios from 'axios';

function App() {
  const dispatch = useDispatch();
  const refTok = useSelector((state)=>state.session.refTok);
  const session = useSelector((state)=>state.session);
  const onLogout = () => dispatch(setLogout());
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if(error.response?.status===401) {
        onLogout();
        return;
      } else {
        return Promise.reject(error);
      }
    }
  )
  useEffect(()=>{
    if(refTok) {
      dispatch(setRefresh())
    } else return cleanup;
  },[]);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
