import React from 'react';
import './CustomCheckbox.scss';

function CustomCheckbox({ id, checked, onChange, value }) {
    return (
        // <React.Fragment>
        <div className='checkboxArea'>
            <input id={id} className='customCheckbox' type="checkbox" checked={checked} onChange={onChange} value={value} />
            <label htmlFor={id} className='customCheckboxLabel'>
                <div className={checked ? "labelDiv checked" : "labelDiv"} />
            </label>
        </div>
        // </React.Fragment>
    );
}

export default CustomCheckbox;