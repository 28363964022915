import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { ConfirmContext } from '../components/ConfirmModal';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import createIconImg from '../../images/createIcon.png';
import editDSIcon from '../../images/editDS.png';
import deleteDSIcon from '../../images/deleteDS.png';
import RefValueModal from './RefValueModal';

function ShowDataStructures(props) {
    const { sumDataStructures, dataStructures } = useOutletContext();
    const navigate = useNavigate();
    const { confirm } = useContext(ConfirmContext);
    const [refValOpen, setRefValOpen] = useState(false);
    const [refValData, setRefValData] = useState(null);


    const onClickEditBtn = (e, selData) => {
        e.preventDefault();
        navigate(`/home/dataStructure/edit/${selData}`);
    };

    const onClickCreateBtn = (e) => {
        e.preventDefault();
        navigate('/home/dataStructure/create');
    };

    const onClickDeleteBtn = async (e, selData) => {
        e.preventDefault();
        const selectedData = dataStructures.filter(el => el.dataStructureId === selData)[0];
        const result = await confirm(`${selectedData.setName}를(을) 삭제하시겠습니까?`, '삭제', '취소');
        if (result) {
            // 삭제
            await axios.post(`${API_URL}/monitoring-manage/deleteDataStructure`, { dataStructureId: selData }).then(res => {
                const { success } = res.data;
                if (success) {
                    navigate(0);
                }
            })
        }
    };

    const onOpenRefValPopup = async (e, dataStructureId) => {
        e.preventDefault();
        await axios.post(`${API_URL}/monitoring-manage/getRefValData`, { dataStructureId }).then((res) => {
            const { refVal } = res.data;
            console.log(refVal);
            setRefValData(refVal);
            setRefValOpen(true);
        }).catch(err => console.error(err));
    };

    return (
        <React.Fragment>

            <div className='showDataStructures'>
                <p className='path'>데이터 구조 관리<span className='below'>{'>'}</span>데이터 구조</p>
                <h2 className='pageTitle'>데이터 구조</h2>
                <div className='sumDsArea'>
                    {
                        sumDataStructures.map((el, idx) => {
                            return (
                                <div className='dataStructureTblArea' key={idx}>
                                    <ul className='dataStructureTbl'>
                                        <li className='row th sumDs'>
                                            <ul>
                                                <li className='col'>
                                                    company
                                                </li>
                                                <li className='col value'>
                                                    {el.company}
                                                </li>
                                                <li className='col'>
                                                    topicMQ
                                                </li>
                                                <li className='col value'>
                                                    {el.topicMQ}
                                                </li>
                                                <li className='col vacant'></li>
                                            </ul>
                                        </li>
                                        <li className='row th'>
                                            <ul>
                                                <li className='col'>setName</li>
                                                <li className='col'>noRecords</li>
                                                <li className='col'>name</li>
                                                <li className='col'>dataName</li>
                                                <li className='col'>type</li>
                                                <li className='col'>gain</li>
                                                <li className='col'>offset</li>
                                                <li className='col'>dimension</li>
                                                <li className='col'>locations</li>
                                                <li className='col'>samplingRate</li>
                                                <li className='col'>topicMQ</li>
                                                <li className='col'>company</li>
                                                <li className='col'>dataLevel</li>
                                                <li className='col'>기준값 관리</li>
                                                <li className='col'>comment</li>
                                                <li className='col'>수정</li>
                                                <li className='col'>삭제</li>
                                            </ul>
                                        </li>
                                        {
                                            dataStructures.map((item, index, array) => {
                                                if (item.sumDsId === el.sumDsId) {
                                                    return (
                                                        <li key={index} className='row'>
                                                            <ul>
                                                                <li className='col'>{item.setName}</li>
                                                                <li className='col'>{item.noRecords}</li>
                                                                <li className='col'>{item.name}</li>
                                                                <li className='col'><ul>{
                                                                    item.dataName.split(';').map((e, idx) => <li className='innerCol' key={idx}>{e}</li>)
                                                                }</ul></li>
                                                                <li className='col'><ul>{
                                                                    item.type.split(';').map((e, idx) => <li className='innerCol' key={idx}>{e}</li>)
                                                                }</ul></li>
                                                                <li className='col'><ul>{
                                                                    item.gain.split(';').map((e, idx) => <li className='innerCol' key={idx}>{e}</li>)
                                                                }</ul></li>
                                                                <li className='col'><ul>{
                                                                    item.offset.split(';').map((e, idx) => <li className='innerCol' key={idx}>{e}</li>)
                                                                }</ul></li>
                                                                <li className='col'><ul>{
                                                                    item.dimension.split(';').map((e, idx) => <li className='innerCol' key={idx}>{e}</li>)
                                                                }</ul></li>
                                                                <li className='col'><ul>{
                                                                    item.locations.split(';').map((e, idx) => <li className='innerCol' key={idx}>{e}</li>)
                                                                }</ul></li>
                                                                <li className='col'>{item.samplingRate}</li>
                                                                <li className='col'>{item.topicMQ}</li>
                                                                <li className='col'>{item.company}</li>
                                                                <li className='col'>{item.dataLevel}</li>
                                                                <li className='col'>
                                                                    <button className='refValBtn' onClick={e => onOpenRefValPopup(e, item.dataStructureId)}>관리</button>
                                                                </li>
                                                                <li className='col'>{item.comment}</li>
                                                                <li className='col'>
                                                                    <div className='innerDiv'>
                                                                        <button onClick={e => onClickEditBtn(e, item.dataStructureId)}>
                                                                            <img src={editDSIcon} alt="수정" />
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                                <li className='col'>
                                                                    <div className='innerDiv'>
                                                                        <button onClick={e => onClickDeleteBtn(e, item.dataStructureId)}>
                                                                            <img src={deleteDSIcon} alt="삭제" />
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    );
                                                }
                                            })
                                        }
                                    </ul>
                                    <div className='btnsArea'>
                                        <button className='createBtn' onClick={onClickCreateBtn}>추가<img src={createIconImg} alt={"추가"} /></button>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>

            </div>
            <RefValueModal
                isOpen={refValOpen}
                onRequestClose={e => {
                    e.preventDefault();
                    setRefValOpen(false);
                }}
                refVal={refValData}
            />
        </React.Fragment>
    );
}

export default ShowDataStructures;