import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { API_URL } from '../../../../config/constants';

function DataModelTDs({ index, modelId, modelTypes, modelType, modelName, modelNo, onChangeValue, userIsNormal }) {
    const [modelNamesOpt, setModelNamesOpt] = useState([]);
    const [modelNosOpt, setModelNosOpt] = useState([]);
    const [curType, setCurType] = useState('');
    const [curName, setCurName] = useState('');
    const [curNo, setCurNo] = useState({ modelId: -1, modelNo: '' });
    const modelTypeOpt = modelTypes.map(ele => ({ label: ele, value: ele }));
    const [loading, setLoading] = useState(true);
    async function modelInit(modelId) {
        await axios.post(`${API_URL}/android/getModelById`, { modelId }).then(res => {
            const { modelNames, modelNos } = res.data;
            setModelNamesOpt([...modelNames.map(item => ({ label: item, value: item }))]);
            setModelNosOpt([...modelNos.map(item => ({ label: item.modelNo, value: item }))]);
        }).catch(e => console.error(e)).finally(() => {
            setLoading(false);
        });
    }

    const modelTypeControl = ({ children, ...rest }) => (
        <components.Control {...rest}>
            {children}
            <div className='hintArea'>
                <span className='hintSpan'>{curType}</span>
            </div>
        </components.Control>
    );

    const modelNameControl = ({ children, ...rest }) => (
        <components.Control {...rest}>
            {children}
            <div className='hintArea'>
                <span className='hintSpan'>{curName}</span>
            </div>
        </components.Control>
    );

    const modelNoControl = ({ children, ...rest }) => (
        <components.Control {...rest}>
            {children}
            <div className='hintArea'>
                <span className='hintSpan'>{curNo.modelNo}</span>
            </div>
        </components.Control>
    );

    useEffect(() => {
        modelInit(modelId);
        setCurType(modelType);
        setCurName(modelName);
        setCurNo({ modelId, modelNo });
    }, [modelId]);
    return (
        loading ? <td colSpan={3}>로딩중</td> :
            <React.Fragment>
                <td className='dataModelType'>
                    <Select
                        options={modelTypeOpt}
                        value={modelTypeOpt.filter(val => val.value === curType)}
                        openMenuOnClick={!userIsNormal}
                        onChange={async (sel) => {
                            await axios.post(`${API_URL}/android/getModelNames`, { modelType: sel.value }).then(res => {
                                const modelNamesArr = [...res.data];
                                setModelNamesOpt([...modelNamesArr.map(ele => ({ label: ele, value: ele }))]);
                                setCurType(sel.value);
                            }).catch(err => console.error(err));
                        }}
                        isSearchable={false}
                        classNames={{
                            control: (state) => state.menuIsOpen ? 'control menuOpened' : 'control',
                            container: (state) => 'selectBox',
                            dropdownIndicator: (state) => 'arrow',
                            menu: (state) => 'options',
                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                            singleValue: (state) => 'singleVal',
                            valueContainer: (state) => 'valueContainer',
                        }}
                        components={{
                            Control: modelTypeControl,
                            DropdownIndicator: (state) => null,
                            IndicatorSeparator: (state) => null,
                        }}
                    />
                </td>
                <td className='dataModelName'>
                    <Select
                        options={modelNamesOpt}
                        value={modelNamesOpt.filter(val => val.value === curName)}
                        openMenuOnClick={!userIsNormal}
                        onChange={async (sel) => {
                            await axios.post(`${API_URL}/android/getModelNos`, { modelType: curType, modelName: sel.value }).then(res => {
                                const modelNosArr = res.data.map(item => ({ label: item.modelNo, value: item }));
                                setCurName(sel.value);
                                setModelNosOpt([...modelNosArr]);
                            }).catch(err => console.error(err));
                            // onChangeValue(index, 'modelName', sel.value);
                        }}
                        isSearchable={false}
                        classNames={{
                            control: (state) => state.menuIsOpen ? 'control menuOpened' : 'control',
                            container: (state) => 'selectBox',
                            dropdownIndicator: (state) => 'arrow',
                            menu: (state) => 'options',
                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                            singleValue: (state) => 'singleVal',
                            valueContainer: (state) => 'valueContainer',
                        }}
                        components={{
                            Control: modelNameControl,
                            DropdownIndicator: (state) => null,
                            IndicatorSeparator: (state) => null,
                        }}
                    />
                </td>
                <td className='dataModelNo'>
                    <Select
                        options={modelNosOpt}
                        value={modelNosOpt.filter(val => val.value.modelNo === curNo.modelNo)}
                        openMenuOnClick={!userIsNormal}
                        onChange={sel => {
                            setCurNo(sel.value);
                            onChangeValue(index, { modelType: curType, modelName: curName, modelNo: sel.value.modelNo }, sel.value.modelId);
                        }}
                        isSearchable={false}
                        classNames={{
                            control: (state) => state.menuIsOpen ? 'control menuOpened' : 'control',
                            container: (state) => 'selectBox',
                            dropdownIndicator: (state) => 'arrow',
                            menu: (state) => 'options',
                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                            singleValue: (state) => 'singleVal',
                            valueContainer: (state) => 'valueContainer',
                        }}
                        components={{
                            Control: modelNoControl,
                            DropdownIndicator: (state) => null,
                            IndicatorSeparator: (state) => null,
                        }}
                    />
                </td>
            </React.Fragment>
    );
}

export default DataModelTDs;