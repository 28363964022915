import React from 'react';
import Modal from 'react-modal';
import './LoginPageModal.scss';
import axios from 'axios';
import { API_URL } from '../../../config/constants';

Modal.setAppElement('#root');

const customStyle = {
    overlay: {
        backgroundColor: 'transparent'
        // zIndex: 98,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '10px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
    },
}

function ResetPwModal({isOpen, onRequestClose, userEmail}) {
    async function onSubmit(e) {
        e.preventDefault();
        await axios.post(`${API_URL}/monitoring-manage/reqChgPwEmail`, {userEmail: userEmail}).then(res => {
            const {success} = res.data;
            if(success) {
                onRequestClose();
            }
        })
    }
    return (
        <Modal
            isOpen={isOpen}
            style={customStyle}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={false}
        >
            <div className='loginPageModal notApprovedYet'>
                <div className='msg'>
                    <p>비밀번호를 5회 틀리셨습니다.<br/>5분간 로그인이 제한됩니다.<br/>비밀번호 변경을 원하신다면 이메일 요청 버튼을 클릭하세요.</p>
                </div>
                <button className='goBackBtn' onClick={onSubmit}>이메일 요청</button>
            </div>
        </Modal>
    );
}

export default ResetPwModal;