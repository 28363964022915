import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setLogin } from '../../reducer/authentication';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import './LoginPage.scss';
import logoImg from '../../images/logo.png';
import idIconImg from '../../images/idIcon.png';
import pwIconImg from '../../images/pwIcon.png';
import { Link, useSearchParams } from 'react-router-dom';
import DeniedUserModal from './components/DeniedUserModal';
import NotApprovedYetPopup from './components/NotApprovedYetModal';
import Footer from '../components/Footer';
import naverLogin from '../../images/naverLogin.png';
import RegisteredEmailModal from './components/RegisteredEmailModal';
import WrongPwModal from './components/WrongPwModal';
import ResetPwModal from './components/ResetPwModal';

function LoginPage(props) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const factor = searchParams.get("factor");

    let defaultData = {
        userEmail: '',
        userPw: '',
    };
    const [loginData, setLoginData] = useState(defaultData);
    const onChange = (event) => {
        const {
            target: { name, value },
        } = event;
        setLoginData({
            ...loginData, [name]: value
        });
    };

    // 로그인 실패시
    const [fail, setFail] = useState(false);
    const [notApproved, setNotApproved] = useState(false);
    const [deniedUser, setDeniedUser] = useState(false);
    const [registeredEmail, setRegisteredEmail] = useState(false);
    const [wrongPw, setWrongPw] = useState(false);
    const [failedCount, setFailedCount] = useState(0);
    const [resetPw, setResetPw] = useState(false);

    const navigate = useNavigate();
    const onLogin = (data) => dispatch(setLogin(data));
    const onSubmit = (e) => {
        e.preventDefault();

        axios.post(`${API_URL}/monitoring-manage/loginManagePage`, loginData)
            .then((res) => {
                if (res.data.message) {
                    console.log(res.data);
                    if (res.data.message.reason === "Not verified yet") {
                        navigate(`/notVerifiedYet/${loginData.userEmail}`);
                    } else if (res.data.message.reason === "Not approved yet") {
                        setNotApproved(true);
                        setFail(false)
                    } else if (res.data.message.reason === "Denied user") {
                        setDeniedUser(true);
                        setFail(false)
                    } else {
                        if (res.data.message.count && res.data.message.count >= 5) {
                            setResetPw(true);
                        }else if (res.data.message.count && res.data.message.count > 2) {
                            setFailedCount(res.data.message.count);
                            setWrongPw(true);
                        } 
                        setFail(true);
                    }
                } else {
                    // 로그인 성공
                    setFail(false)
                    onLogin(res.data);
                    // 직전 페이지로 이동
                    navigate(res.data.userRank === 1 ? '/home/members' : '/home/data/sumData');
                }
            }).catch(err => {
                console.error(err)
            });
    }
    const onSignInByNaver = (e) => {
        e.preventDefault();
        window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&redirect_uri=${API_URL}/monitoring-manage/naverCallback&client_id=${process.env.REACT_APP_NAVER_ID}`;
    }

    useEffect(() => {
        if (factor === 'registeredEmail') {
            setRegisteredEmail(true);
        } else if (factor === 'notApproved') {
            setNotApproved(true);
        } else if (factor === 'denied') {
            setDeniedUser(true);
        }
    }, [factor])
    return (
        <React.Fragment>
            <div className='container'>
                <div className='background'>
                    <div className='loginPanel'>
                        <div className='loginTitleArea'>
                            <h2 className='loginTitle'>Login</h2>
                            <div className='loginTitleKrArea'>
                                <img src={logoImg} alt={"공정 모니터링 시스템"} />
                                <p>공정 모니터링 시스템</p>
                            </div>
                        </div>
                        <div className='formNBtnsArea'>

                            <form className='signinById' onChange={onChange} onSubmit={onSubmit}>
                                <div className='inputArea'>

                                    <div className='inputBg'>
                                        <div className='iconNLabel'>
                                            <img src={idIconImg} alt="이메일" />
                                            <label htmlFor='userEmail'>이메일</label>
                                        </div>
                                        <input name='userEmail' id='loginId' type='email' placeholder='이메일' />
                                    </div>
                                    <div className='inputBg'>
                                        <div className='iconNLabel'>
                                            <img src={pwIconImg} alt='비밀번호' />
                                            <label htmlFor='loginPw'>비밀번호</label>
                                        </div>
                                        <input name='userPw' id='loginPw' type='password' placeholder='비밀번호' />
                                    </div>
                                    <span className={fail ? 'loginFailed fail' : 'loginFailed'}>
                                        이메일 또는 비밀번호가 일치하지 않습니다.
                                    </span>
                                </div>
                                <div className='btnsArea'>
                                    <input type='submit' className='loginBtn' value='Login' />
                                    <Link className='signupBtn' to="/register">회원가입</Link>
                                    {/* <a href={`https://nid.naver.com/oauth2.0/authorize?response_type=code&redirect_uri=${API_URL}/monitoring-manage/naverCallback&client_id=${process.env.REACT_APP_NAVER_ID}`}>네이버</a> */}
                                </div>
                            </form>
                            <div className='socialLoginBtnsArea'>
                                <p>간편하게 시작하기</p>
                                <ul className='socialLoginBtnsUl'>
                                    <li>
                                        <button className='naverLoginBtn' onClick={onSignInByNaver}>
                                            <img src={naverLogin} alt='네이버로그인' />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <DeniedUserModal isOpen={deniedUser} onRequestClose={() => { setDeniedUser(false) }} />
            <NotApprovedYetPopup isOpen={notApproved} onRequestClose={() => { setNotApproved(false) }} />
            <RegisteredEmailModal isOpen={registeredEmail} onRequestClose={() => { setRegisteredEmail(false) }} />
            <WrongPwModal isOpen={wrongPw} onRequestClose={() => { setWrongPw(false); setFailedCount(0); }} failedCount={failedCount} />
            <ResetPwModal isOpen={resetPw} onRequestClose={() => { setResetPw(false) }} userEmail={loginData.userEmail} />

        </React.Fragment>
    );
}

export default LoginPage;