import React, { useEffect, useState } from 'react';
import SumData from './sumData';
import RealTimeMonitoring from './monitoring';
import './data.scss';
import Model from './model';
import UserRank from './userRank';
import { Outlet, Route, Routes, useLocation, useOutletContext } from 'react-router';
import { Link } from 'react-router-dom';
import sumDataIcon_on from '../../images/sumData_on.png';
import sumDataIcon_off from '../../images/sumData_off.png';
import monitoringIcon_on from '../../images/monitoring_on.png';
import monitoringIcon_off from '../../images/monitoring_off.png';
import modelIcon_on from '../../images/model_on.png';
import modelIcon_off from '../../images/model_off.png';
import userRankIcon_on from '../../images/userRank_on.png';
import userRankIcon_off from '../../images/userRank_off.png';
import { useSelector } from 'react-redux';
import { API_URL } from '../../config/constants';
import axios from 'axios';

function Data(props) {
    const { normalRank } = useOutletContext();
    const { pathname } = useLocation();
    const { userRank } = useSelector((state) => state.session);
    return (
        <div className='dataPage'>
            <div className='dataMenuArea'>
                <ul className='dataMenuUl'>
                    <li className='dataMenuLi'>
                        <Link className={pathname.split('/')[3] === 'sumData' ? 'dataMenuBtn on' : 'dataMenuBtn'} to={'/home/data/sumData'}>
                            <img className='dataMenuIcon' src={pathname.split('/')[3] === 'sumData' ? sumDataIcon_on : sumDataIcon_off} alt={"공정 요약"} />
                            <span>공정 요약</span>
                        </Link>
                    </li>
                    <li className='dataMenuLi'>
                        <Link className={pathname.split('/')[3] === 'monitoring' ? 'dataMenuBtn on' : 'dataMenuBtn'} to={'/home/data/monitoring'}>
                            <img className='dataMenuIcon' src={pathname.split('/')[3] === 'monitoring' ? monitoringIcon_on : monitoringIcon_off} alt={"세부 데이터"} />
                            <span>세부 데이터</span>
                        </Link>
                    </li>
                    <li className='dataMenuLi'>
                        <Link className={pathname.split('/')[3] === 'model' ? 'dataMenuBtn on' : 'dataMenuBtn'} to={'/home/data/model'} disabled={userRank === normalRank}>
                            <img className='dataMenuIcon' src={pathname.split('/')[3] === 'model' ? modelIcon_on : modelIcon_off} alt={"모델"} />
                            <span>모델</span>
                        </Link>
                    </li>
                    <li className='dataMenuLi'>
                        <Link className={pathname.split('/')[3] === 'userRank' ? 'dataMenuBtn on' : 'dataMenuBtn'} to={'/home/data/userRank'} disabled={userRank !== 1}>
                            <img className='dataMenuIcon' src={pathname.split('/')[3] === 'userRank' ? userRankIcon_on : userRankIcon_off} alt={"유저 랭크"} />
                            <span>유저 랭크</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className='dataContentArea'>
                <Outlet context={{userIsNormal: userRank === normalRank}} />
            </div>
        </div>
    );
}

export default Data;