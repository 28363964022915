import { Outlet, useLocation, useOutletContext } from 'react-router';
import './Homepage.scss';
import { setLogout } from '../reducer/authentication';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../config/constants';
import membersIconImg from '../images/membersIcon.png';
import membersIconDisImg from '../images/membersIconDisabled.png';
import dataIconImg from '../images/dataIcon.png';
import dataIconDisImg from '../images/dataIconDisabled.png';
import dataStructureIconImg from '../images/dataStructureIcon.png';
import dataStructureIconDisImg from '../images/dataStructureIconDisabled.png';
import logoImg from '../images/logo.png';
import defaultProfilePic from '../images/profilePic.png';
import { useContext, useEffect, useState } from 'react';
import { ConfirmContext } from './components/ConfirmModal';
import { Link } from 'react-router-dom';
import Footer from './components/Footer';
import Loading from './components/Loading';
import useAsync from '../hooks/useAsync';

function Homepage() {
    async function getNormalRank() {
        const response = await axios.post(`${API_URL}/monitoring-manage/getUserRankByRole`, { role: 'normal' });
        return response.data;
    }
    const dispatch = useDispatch();
    const { confirm } = useContext(ConfirmContext);
    const onLogout = () => dispatch(setLogout());
    const onClick = async (e) => {
        e.preventDefault();
        const result = await confirm("로그아웃 하시겠습니까?", "네", "아니오");
        if (result) {
            onLogout()
            return await axios.post(`${API_URL}/monitoring-manage/logoutManagePage`).then((_) => {
                // window.location.href = '/';
            }).catch(e => { console.error(e) });
        } else {
            return false;
        }
    };

    const location = useLocation();
    const { pathname } = location;
    const [isOn, setIsOn] = useState('');
    const { userName, userRank } = useSelector((state) => state.session);
    useEffect(() => {
        const routeName = pathname.split('/')[2];
        setIsOn(routeName);
    }, [pathname])


    const state = useAsync(getNormalRank);
    const { loading, error, data: result } = state;
    if (loading) return <div className='contentArea' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='contentArea'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return <div>실패</div>;
    return (
        <div className='container'>
            <div className="mainPage">
                <nav className="sideBar">
                    <ul>
                        <li>
                            <Link to='/home/members' className='membersBtn' disabled={userRank !== 1}>
                                <img src={isOn === "members" || userRank !== 1 ? membersIconDisImg : membersIconImg} alt="members" />
                                <span className='hint'>회원 관리</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/home/data/sumData' className='dataBtn'>
                                <img src={isOn === "data" ? dataIconDisImg : dataIconImg} alt="data" />
                                <span className='hint'>데이터 관리</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/home/dataStructure' className='dataStructureBtn' disabled={userRank === result.rank}>
                                <img src={isOn === "dataStructure" ? dataStructureIconDisImg : dataStructureIconImg} alt="dataStructure" />
                                <span className='hint'>데이터 구조 관리</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                {/* <div className='navArea'>
                </div> */}
                <div className="contentArea">
                    <header>
                        <a href={userRank !== 1 ? '/home/data/sumData' : '/home/members'}>
                            <div className='homeBtnArea'>
                                <img src={logoImg} alt={"공정 모니터링 시스템"} />
                                <p>공정 모니터링 시스템</p>
                            </div>
                        </a>
                        <div className='myAccountArea'>
                            <Link to='/home/mypage'>
                                <div className='myAccount'>
                                    <img src={defaultProfilePic} alt={'내 정보'} />
                                    <p>
                                        {userName}님 (LV.{userRank})
                                    </p>
                                </div>
                            </Link>
                            <button onClick={onClick}>로그아웃</button>
                        </div>
                    </header>
                    <div className="content">
                        <Outlet context={{
                            normalRank: result.rank,
                        }} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Homepage;