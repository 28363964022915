import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './RegisterSucNoti.scss';

Modal.setAppElement('#root');

function RegisterSucNoti({ showNoti, onClose }) {
    const [timeLeft, setTimeLeft] = useState(3);
    const onClick = e => {
        e.preventDefault();
        onClose();
    };
    useEffect(() => {
        if (showNoti) {
            const timer = setInterval(() => {
                setTimeLeft(prev => prev - 1);
            }, 1000);
            const disappear = setTimeout(() => {
                onClose();
            }, 2700);
            return () => {
                clearInterval(timer);
                clearTimeout(disappear);
            }
        }
    }, [showNoti]);
    return (
        <Modal
            isOpen={showNoti}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.24)',
                    // zIndex: 99,
                    // pointerEvents: 'none',
                },
                content: {
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    padding: 'none',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)',
                    // zIndex: 100,
                },
            }}
            onRequestClose={onClose}
            className={'notiWindow'}
            shouldCloseOnOverlayClick={false}
        >
            <div className='innerDiv'>
                <div className='msgArea'>
                    <p>회원가입 신청이 완료되었습니다.</p>
                    <p>관리자 승인 이후 회원가입이 완료됩니다.</p>
                </div>
                <button onClick={onClick}>{`로그인화면 이동 (${timeLeft}초)`}</button>
            </div>
        </Modal>
    );
}

export default RegisterSucNoti;