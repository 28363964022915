import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import closeIconImg from '../../images/closeIcon.png';
import './ChangePwModal.scss';
import axios from 'axios';
import { API_URL } from '../../config/constants';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.24)',
        zIndex: 98
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '9px',
        boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
    },
};

Modal.setAppElement('#root');

function ChangePwModal({ isOpen, onRequestClose, trySubmit }) {
    const inputRef = useRef([]);
    const [formData, setFormData] = useState({
        curPw: '',
        newPw: '',
        newPwChk: '',
    });
    const errorStateDefault = {
        curPwError: false,
        newPwError: '',
        newPwChkError: false,
    };
    const [errorOccurred, setErrorOccurred] = useState(errorStateDefault);
    const { curPwError, newPwError, newPwChkError } = errorOccurred;

    const onClose = e => {
        onRequestClose(e);
        setFormData({
            curPw: '',
            newPw: '',
            newPwChk: '',
        });
    }

    const onChange = e => {
        e.preventDefault();
        setErrorOccurred({
            ...errorOccurred, [`${e.target.name}Error`]: errorStateDefault[`${e.target.name}Error`]
        });
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }
    const onBlurPw = e => {
        // 비밀번호 형식 정규식
        // 비밀번호는 영문, 숫자, 특수문자를 모두 포함하고, 공백이 없는 8~15자
        let reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
        // 공백 체크
        const pwHasSpace = e.target.value.indexOf(" ") !== -1;
        const hasProblems = !(reg.test(e.target.value) && !pwHasSpace)
        if(hasProblems) {
            setErrorOccurred({ ...errorOccurred, newPwError: 'invalid' });
        }
    };
    const onBlurPwChk = e => {
        if (newPwError) return;
        setErrorOccurred({ ...errorOccurred, newPwChkError: formData.newPw !== formData.newPwChk });
    };

    const onSubmit = async e => {
        e.preventDefault();
        // 신규 비밀번호와 신규 비밀번호 확인이 일치하는지
        const { curPw, newPw, newPwChk } = formData;
        if (curPw === newPw) {
            // 새로운 비밀번호 입력 요청
            setErrorOccurred({ ...errorOccurred, newPwError: 'dup' });
        } else if (newPw !== newPwChk) {
            // 신규 비밀번호와 신규 비밀번호 확인이 일치하지 않음
        } else {
            // API 요청
            await axios.post(`${API_URL}/monitoring-manage/changePw`, { curPw, newPw }).then(res => {
                const { success } = res.data;
                if (success) {
                    // 변경 성공시 모달 종료
                    onRequestClose(e);
                    trySubmit(true);
                } else {
                    trySubmit(false);
                    setErrorOccurred({...errorOccurred, curPwError: true});
                    inputRef.current.forEach(el => {
                        el.value = '';
                    });
                }
            })
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel='비밀번호 변경'
        >
            <div className='modalContentArea changePwModal'>
                <button className='closeModal' onClick={onClose}><img src={closeIconImg} alt='창 닫기' /></button>
                <h3 className='modalTitle'>비밀번호 변경</h3>
                <form onChange={onChange} onSubmit={onSubmit}>
                    <div className='inputArea'>
                        <p className={!curPwError ? 'changePwInputP' : 'changePwInputP error'}>
                            <input name='curPw' type='password' placeholder='현재 비밀번호 입력' autoComplete="off" ref={el => inputRef.current[0] = el} />
                            <span className='errMsg'>현재 비밀번호가 일치하지 않습니다.</span>
                        </p>
                        <p className={`changePwInputP ${newPwError}`}>
                            <input name='newPw' type='password' placeholder='신규 비밀번호 입력' onBlur={onBlurPw} ref={el => inputRef.current[1] = el} />
                            <span className='errMsg invalid'>영문, 숫자, 특수문자를 모두 포함하여 공백없이 8~15자 구성</span>
                            {/* <span className='errMsg dup'>현재 비밀번호와 다른 비밀번호를 입력해주세요.</span> */}
                        </p>
                        <p className={!newPwChkError ? 'changePwInputP' : 'changePwInputP error'}>
                            <input name='newPwChk' type='password' placeholder='신규 비밀번호 확인' autoComplete="off" onBlur={onBlurPwChk} ref={el => inputRef.current[2] = el} />
                            <span className='errMsg'>신규 비밀번호와 일치하지 않습니다.</span>
                        </p>
                    </div>
                    <div className='btnsArea'>
                        <input type='submit' className='formBtn submitBtn' value={"변경"} />
                        <button className='formBtn cancelBtn' onClick={onClose} >취소</button>

                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default ChangePwModal;