import React from 'react';
import kanLogoImg from '../../images/kanLogo.png';
import './Footer.scss';

function Footer(props) {
    return (
        <footer className='footer'>
            <img className='kanLogo' src={kanLogoImg} alt='케이에이앤' />
            <p className='copyright'>Copyright © 케이에이앤 All Right Reserved.</p>
        </footer>
    );
}

export default Footer;