import React from 'react';
import logoImg from '../../images/logo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import './AuthMailInfo.scss';

function AuthMailInfo({ userEmail }) {
    const onRequestResend = async (e) => {
        e.preventDefault();
        await axios.post(`${API_URL}/monitoring-manage/resendAuthMail`, { userEmail });
    };
    return (
        <div className='authMailInfo registerPanel'>
            <h1>이메일 인증</h1>
            <h2><img className='logoImg' src={logoImg} alt="로고" />공정 모니터링 시스템</h2>
            <div className='msgArea'>
                <p>인증 메일이 아래의 메일주소로 전송되었습니다.<br /><span className='bold'>1</span>시간 이내로 인증을 완료해주세요.</p>
                <div className='userEmail'>
                    <p>{userEmail}</p>
                </div>
            </div>
            <Link to={'/'} className='toLogIn'>로그인 화면으로</Link>
            <div className='resendEmailArea'>
                <button className='resendEmail' onClick={onRequestResend}>인증메일 재전송</button>
                <p className='resendEmailMsg'>메일을 못 받으셨나요? 위 버튼을 클릭해주세요.</p>
            </div>
        </div>
    );
}

export default AuthMailInfo;