import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

function Summary(props) {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        if(currentPath === '/home' || currentPath === '/home/') {
            navigate('/home/members');
        }
    } , [currentPath, navigate]);

    return (
        <div>
            <h2>Summary</h2>
        </div>
    );
}

export default Summary;