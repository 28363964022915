import React, { useEffect, useState } from 'react';
import logoImg from '../../images/logo.png';
import './RegisterPage.scss';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import RegisterSucNoti from './components/RegisterSucNoti';
import AuthMailInfo from './AuthMailInfo';
import Footer from '../components/Footer';

function RegisterPage(props) {
    const [formData, setFormData] = useState({
        userEmail: '',
        userPw: '',
        userPwChk: '',
        userName: '',
        userCompany: '',
    });
    const [tempEmail, setTempEmail] = useState('');
    const [dupChkComplete, setDupChkComplete] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState({
        userEmailError: false,
        userPwError: false,
        userPwChkError: false,
        userNameError: false,
        userCompanyError: false,
    });
    const [showNoti, setShowNoti] = useState(false);
    const { userEmailError, userPwError, userPwChkError, userNameError, userCompanyError } = errorOccurred;
    const { userEmail, userPw, userPwChk, userName, userCompany } = formData;

    const onChange = e => {
        // e.preventDefault();
        if (e.target.name === 'userEmail') {
            setTempEmail(e.target.value);
            setDupChkComplete(false);
            setErrorOccurred(prevState => ({ ...prevState, userEmailError: false }));
            setFormData(prevState => ({ ...prevState, userEmail: '' }));
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const onChkDupEmail = async (e) => {
        e.preventDefault();
        if (userEmailError) return;
        axios.post(`${API_URL}/monitoring-manage/chkDupEmail`, { userEmail: tempEmail }).then(res => {
            const { isDup } = res.data;
            if (isDup) {
                setFormData({ ...formData, userEmail: '' });
                setErrorOccurred({ ...errorOccurred, userEmailError: 'dupEmail' });
            } else {
                // setErrorOccurred({ ...errorOccurred, userEmailError: false });
                setFormData({ ...formData, userEmail: tempEmail });
                setDupChkComplete(true);
            };
        }).catch(err => console.error(err));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const errorExist = !Object.values(errorOccurred).every(x => x === false);
        if (errorExist) return;
        if (userEmail.length === 0) { setErrorOccurred({ ...errorOccurred, userEmailError: 'noValue' }); }
        else if (userPw.length === 0) { setErrorOccurred({ ...errorOccurred, userPwError: true }); }
        else if (userPw !== userPwChk) { setErrorOccurred({ ...errorOccurred, userPwChkError: true }); }
        else if (userName.length === 0) { setErrorOccurred({ ...errorOccurred, userNameError: true }); }
        else if (userCompany.length === 0) { setErrorOccurred({ ...errorOccurred, userCompanyError: true }); }
        else {
            await axios.post(`${API_URL}/monitoring-manage/register`, { userEmail, userPw, userName, userCompany })
                .then(res => {
                    if (res.data.success) {
                        setShowNoti(true);
                    }
                }).catch(err => console.error(err));
        }
    };

    const onBlurEmail = e => {
        // 이메일 형식 정규식
        var regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regEmail.test(tempEmail)) {
            setFormData({ ...formData, userEmail: '' });
            setErrorOccurred({ ...errorOccurred, userEmailError: 'invalidEmail' });
        }
    };

    const onBlurPw = e => {
        // 비밀번호 형식 정규식
        // 비밀번호는 영문, 숫자, 특수문자를 모두 포함하고, 공백이 없는 8~15자
        let reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
        // 공백 체크
        const pwHasSpace = e.target.value.indexOf(" ") !== -1;
        const hasProblems = !(reg.test(e.target.value) && !pwHasSpace)

        setErrorOccurred({ ...errorOccurred, userPwError: hasProblems });
    };

    const onBlurPwChk = e => {
        if (userPwError) return;
        setErrorOccurred({ ...errorOccurred, userPwChkError: formData.userPw !== formData.userPwChk });
    };

    const navigate = useNavigate();

    useEffect(() => {
        setErrorOccurred(prevState => ({
            ...prevState,
            userPwError: false,
            userPwChkError: false,
            userNameError: false,
            userCompanyError: false,
        }));
    }, [formData]);

    return (
        <React.Fragment>
            <div className='container'>
                <div className='background'>
                    {!showNoti ?
                        <div className='registerPanel'>
                            <h1>회원가입</h1>
                            <h2><img className='logoImg' src={logoImg} alt="로고" />공정 모니터링 시스템</h2>
                            <form className={'registerForm'} onChange={onChange} onSubmit={onSubmit} autoComplete='off'>
                                <div className={'inputsArea'}>
                                    <div className='emailInputArea'>
                                        <div className={userEmailError !== false ? `userEmail inputArea error ${userEmailError}` : 'userEmail inputArea'}>
                                            <input type='email' name="userEmail" placeholder='이메일' autoComplete="off" onBlur={onBlurEmail} />
                                            <span className='errMsgSpan dupEmailError'>이미 가입되어 있는 이메일입니다. 다시 확인해주세요.</span>
                                            <span className={'errMsgSpan invalidEmailError'}>올바르지 않은 이메일 형식입니다.</span>
                                            <span className={'errMsgSpan noValueError'}>이메일을 입력하세요.</span>
                                            <span className={formData.userEmail.length > 0 ? 'emailDupChkPassed visible' : 'emailDupChkPassed invisible'}>사용 가능한 이메일입니다.</span>
                                        </div>
                                        <button className={'dupChkBtn'} onClick={onChkDupEmail} disabled={dupChkComplete}>중복확인</button>
                                    </div>

                                    <div className={userPwError ? 'inputArea error' : 'inputArea'}>
                                        <input type='password' name='userPw' placeholder='비밀번호' autoComplete="new-password" onBlur={onBlurPw} />
                                        <span className={!userPwError ? 'pwFormatSpan' : 'errMsgSpan'}>비밀번호는 영문, 숫자, 특수문자를 모두 포함하여 공백없이 8~15자로 구성되어야 합니다.</span>
                                    </div>
                                    <div className={userPwChkError ? 'inputArea error' : 'inputArea'}>
                                        <input type='password' name='userPwChk' placeholder='비밀번호 확인' autoComplete="new-password" onBlur={onBlurPwChk} />
                                        <span className={'errMsgSpan pwChkError'}>비밀번호가 일치하지 않습니다.</span>
                                    </div>
                                    <div className={userNameError ? 'inputArea error' : 'inputArea'}>
                                        <input type='text' name='userName' placeholder='이름' />
                                    </div>
                                    <div className={userCompanyError ? 'inputArea error' : 'inputArea'}>
                                        <input type='text' name='userCompany' placeholder='소속' />
                                    </div>
                                </div>
                                <div className='btnsArea'>
                                    <input className='registerBtn' type='submit' value={"제출"} disabled={userEmail.length === 0 || userPw.length === 0 || userPwChk.length === 0 || userName.length === 0 || userCompany.length === 0 || userEmailError || userPwError || userPwChkError || userNameError || userCompanyError} />
                                    <Link className='cancelBtn' to={'/'}>취소</Link>
                                </div>
                            </form>
                        </div>
                        : <AuthMailInfo userEmail={userEmail} />
                    }
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}

export default RegisterPage;