import React, { useEffect, useState } from 'react';
import { Link, useBlocker, useNavigate } from 'react-router-dom';
import { API_URL } from '../../../config/constants';
import axios from 'axios';
import createIconImg from '../../../images/createIcon.png';
import cretaeIconImgOn from '../../../images/createIcon_on.png';
import arrowIconImg from '../../../images/filledArrowIcon.png';
import arrowIconImgDisabled from '../../../images/filledArrowIconBlack.png';
import Select from 'react-select';
import YouWereEditing from '../../components/YouWereEditing';

function ShowModels({ models }) {
    const [modelList, setModelList] = useState([]);
    const [changed, setChanged] = useState(false);
    const [newRow, setNewRow] = useState(false);
    const [newValue, setNewValue] = useState(null);
    const [modelTypes, setModelTypes] = useState([]);
    const [modelNames, setModelNames] = useState([]);
    const [inputMode, setInputMode] = useState({
        modelType: false,
        modelName: false,
    });
    const navigate = useNavigate();

    const onCreateNewRow = e => {
        if (!newValue) {
            setNewValue({ modelType: null, modelName: null, modelNo: null });
            setNewRow(true);
            setChanged(true);
        } else {
            setNewValue(null);
            setNewRow(false);
            setInputMode({ modelType: false, modelName: false });
            chgMonit();
        }
    }
    // 수정 버튼 이벤트
    const handleCreateObject = async e => {
        e.preventDefault();
        // 수정 사항 id(PK)체크 후 해당 데이터만 전송
        const updatedData = modelList.filter(x => !models.includes(x));
        await axios.post(`${API_URL}/monitoring-manage/updateModels`, { updatedData: updatedData, newData: newValue }).then(res => {
            if (res.data.success) {
                // window.location = '/model/edit';
                navigate(0);
                // onReturnDefault(e);
            } else {
                console.error(res.data.error);
            }
        }).catch(err => console.error(err));

    };
    const onReset = e => {
        e.preventDefault();
        setModelList([...models]);
        if (newRow) {
            setNewRow(false);
            setNewValue(null);
        }
        setChanged(false);
    };
    const onChange = (e, index) => {
        let copyArr = [...modelList];
        copyArr[index] = { ...copyArr[index], [e.target.name]: e.target.value };
        setModelList(copyArr);
        setChanged(true);
    };

    const onChangeNew = e => {
        setNewValue({ ...newValue, [e.target.name]: e.target.value });
    };

    const chgMonit = () => {
        const changed = newValue !== null && newValue.modelType !== null && newValue.modelName !== null && (newValue.modelNo !== null && newValue.modelNo.length > 0);
        setChanged(changed);
    };
    console.log(modelTypes);

    async function getModelTypes() {
        await axios.get(`${API_URL}/android/getModelTypes`).then(res => {
            const convertedArr = res.data.map(el => ({ label: el, value: el }));
            setModelTypes([...convertedArr, { label: '직접입력', value: null }]);
        });
    }


    useEffect(() => {
        setModelList([...models]);
        getModelTypes();
    }, [models]);
    useEffect(() => {
        chgMonit();
    }, [newValue]);


    const [blockPopup, setBlockPopup] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) =>
        changed &&
        currentLocation.pathname !== nextLocation.pathname);

    useEffect(() => {
        if (blocker.state === "blocked") {
            setBlockPopup(true);
        } else {
            setBlockPopup(false);
        }
    }, [blocker.state])
    return (
        <React.Fragment>

            <div className='showModelsArea'>
                <p className='path'>데이터 관리<span className='below'>{'>'}</span>모델</p>
                <h2 className='pageTitle'>모델</h2>
                <div className='modelsTblArea'>
                    <div className='hintBtn'>
                        ?
                        <div className='hintMsg'><p>셀을 직접 클릭하여 수정이 가능합니다.</p></div>
                    </div>
                    <ul className='modelsTbl'>
                        <li className='row th'>
                            <ul>
                                <li className='col'>차종</li>
                                <li className='col'>품명</li>
                                <li className='col'>품번</li>
                            </ul>
                        </li>
                        {
                            modelList.map((element, index, array) => {
                                return (
                                    <li key={index} className='row'>
                                        <ul>
                                            <li className='col'>
                                                <div className='inputArea'>
                                                    <input type="text" value={element.modelType} name='modelType' onChange={e => onChange(e, index)} />
                                                </div>
                                            </li>
                                            <li className='col'>
                                                <div className='inputArea'>
                                                    <input type="text" value={element.modelName} name='modelName' onChange={e => onChange(e, index)} />
                                                </div>
                                            </li>
                                            <li className='col'>
                                                <div className='inputArea'>
                                                    <input type="text" value={element.modelNo} name='modelNo' onChange={e => onChange(e, index)} />
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                );
                            })
                        }
                        {
                            newRow ?
                                <li className='row newRow'>
                                    <ul>
                                        <li className='col'>
                                            <div className='inputArea'>
                                                {
                                                    inputMode.modelType ?
                                                        <input type="text" name='modelType' onChange={onChangeNew} />
                                                        : <Select
                                                            options={modelTypes}
                                                            defaultValue={{ label: '+ 추가' }}
                                                            onChange={async sel => {
                                                                if (sel.value === null) {
                                                                    setInputMode({
                                                                        modelType: true,
                                                                        modelName: true
                                                                    });
                                                                    setNewValue({
                                                                        ...newValue,
                                                                        modelType: null,
                                                                        modelName: null,
                                                                        // modelNo: null,
                                                                    })

                                                                } else {
                                                                    await axios.post(`${API_URL}/android/getModelNames`, { modelType: sel.value }).then(res => {
                                                                        setModelNames([...res.data.map(item => ({ label: item, value: item })), { label: '직접입력', value: null }]);
                                                                        setNewValue({
                                                                            modelType: sel.value,
                                                                            modelName: null,
                                                                            modelNo: null
                                                                        });
                                                                    }).catch(err => console.error(err));
                                                                }
                                                            }}
                                                            isSearchable={false}
                                                            classNames={{
                                                                control: (state) => 'control',
                                                                container: (state) => 'selectBox',
                                                                dropdownIndicator: (state) => 'arrow',
                                                                menu: (state) => 'options',
                                                                option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                                singleValue: (state) => 'singleVal',
                                                                valueContainer: (state) => 'valueContainer',
                                                            }}
                                                            components={{
                                                                DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                                IndicatorSeparator: (state) => null,
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </li>
                                        <li className='col'>
                                            <div className='inputArea'>
                                                {
                                                    inputMode.modelName ?
                                                        <input type="text" name='modelName' onChange={onChangeNew} />
                                                        : <Select
                                                            options={modelNames}
                                                            defaultValue={{ label: '' }}
                                                            isDisabled={newValue.modelType === null || newValue.modelType.length === 0}
                                                            onChange={sel => {
                                                                if (sel.value === null) {
                                                                    setInputMode({ ...inputMode, modelName: true });
                                                                } else {
                                                                    setNewValue({
                                                                        ...newValue,
                                                                        modelName: sel.value
                                                                    })
                                                                }
                                                            }}
                                                            isSearchable={false}
                                                            classNames={{
                                                                control: (state) => 'control',
                                                                container: (state) => 'selectBox',
                                                                dropdownIndicator: (state) => 'arrow',
                                                                menu: (state) => 'options',
                                                                option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                                singleValue: (state) => 'singleVal',
                                                                valueContainer: (state) => 'valueContainer',
                                                            }}
                                                            components={{
                                                                DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={newValue.modelType === null || newValue.modelType.length === 0 ? arrowIconImgDisabled : arrowIconImg} alt={'드롭다운'} /></span>,
                                                                IndicatorSeparator: (state) => null,
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </li>
                                        <li className='col'>
                                            <div className='inputArea'>
                                                <input type="text" name='modelNo' onChange={onChangeNew} disabled={(newValue.modelType === null || newValue.modelType.length === 0) || (newValue.modelName === null || newValue.modelName.length === 0)} />
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                : null
                        }
                    </ul>
                </div>
                <div className='btnsArea cscBtns'>
                    <button className={newRow ? 'createBtn on' : 'createBtn'} onClick={onCreateNewRow}>추가<img src={newRow ? cretaeIconImgOn : createIconImg} alt={"추가"} /></button>
                    <button className='editBtn' onClick={handleCreateObject} disabled={!changed}>저장</button>
                    <button className='cancelBtn' onClick={onReset} disabled={!changed}>취소</button>
                </div>
            </div>
            <YouWereEditing
                isOpen={blockPopup}
                onRequestClose={e => { e.preventDefault(); blocker.reset(); }}
                onSubmit={e => { e.preventDefault(); blocker.proceed(); }}
            />
        </React.Fragment>
    );
}

export default ShowModels;