import React, { useEffect, useState } from 'react';
import logoImg from '../../images/logo.png';
import './RegisterPage.scss';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import RegisterSucNoti from './components/RegisterSucNoti';
import AuthMailInfo from './AuthMailInfo';
import Footer from '../components/Footer';
import naverLogin from '../../images/naverLogin.png';

function SocialRegisterPage(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const provider = searchParams.get('provider');
    const [formData, setFormData] = useState({
        userEmail: '',
        userName: '',
        userCompany: '',
    });
    const [tempEmail, setTempEmail] = useState('');
    const [dupChkComplete, setDupChkComplete] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState({
        userEmailError: false,
        userNameError: false,
        userCompanyError: false,
    });
    const [showNoti, setShowNoti] = useState(false);
    const { userEmailError, userNameError, userCompanyError } = errorOccurred;
    const { userEmail, userName, userCompany } = formData;
    const socialId = searchParams.get('id');

    const onChange = e => {
        if (e.target.name === 'userEmail') {
            setTempEmail(e.target.value);
            setDupChkComplete(false);
            setErrorOccurred(prevState => ({ ...prevState, userEmailError: false }));
            setFormData(prevState => ({ ...prevState, userEmail: '' }));
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };
    const onChkDupEmail = async (e) => {
        e.preventDefault();
        if (userEmailError) return;
        axios.post(`${API_URL}/monitoring-manage/chkDupEmail`, { userEmail: tempEmail }).then(res => {
            const { isDup } = res.data;
            if (isDup) {
                setFormData({ ...formData, userEmail: '' });
                setErrorOccurred({ ...errorOccurred, userEmailError: 'dupEmail' });
            } else {
                // setErrorOccurred({ ...errorOccurred, userEmailError: false });
                setFormData({ ...formData, userEmail: tempEmail });
                setDupChkComplete(true);
            };
        }).catch(err => console.error(err));
    };
    const onBlurEmail = e => {
        // 이메일 형식 정규식
        var regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regEmail.test(tempEmail)) {
            setFormData({ ...formData, userEmail: '' });
            setErrorOccurred({ ...errorOccurred, userEmailError: 'invalidEmail' });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const errorExist = !Object.values(errorOccurred).every(x => x === false);
        if (errorExist) return;
        if (userEmail.length === 0) { setErrorOccurred({ ...errorOccurred, userEmailError: 'noValue' }); }
        else if (userName.length === 0) { setErrorOccurred({ ...errorOccurred, userNameError: true }); }
        else if (userCompany.length === 0) { setErrorOccurred({ ...errorOccurred, userCompanyError: true }); }
        else {
            await axios.post(`${API_URL}/monitoring-manage/socialRegister`, { userEmail, userCompany, socialId })
                .then(res => {
                    if (res.data.success) {
                        setShowNoti(true);
                    }
                }).catch(err => console.error(err));
        }
    };

    async function getUserData() {
        await axios.post(`${API_URL}/monitoring-manage/getSocialUserForRegister`, { provider, socialId: socialId }).then(res => {
            const userData = res.data;
            setFormData({ ...formData, userName: userData.userName });
            setTempEmail(searchParams.get('userEmail'));
        }).catch(err => console.error(err));
    }

    const navigate = useNavigate();

    const onCancel = async (e) => {
        e.preventDefault();
        await axios.post(`${API_URL}/monitoring-manage/cancelSocialRegister`, { socialId: socialId }).then(res => {
            if (res.data.success) {
                navigate('/');
            }
        })
    };

    useEffect(() => {
        setErrorOccurred(prevState => ({
            ...prevState,
            userNameError: false,
            userCompanyError: false,
        }));
    }, [formData]);
    useEffect(() => {
        getUserData();
    }, [])
    useEffect(() => {
        const handleUnload = () => {
            axios.post(`${API_URL}/monitoring-manage/cancelSocialRegister`, { socialId: socialId });
        };
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        }
    }, [])
    return (
        <React.Fragment>
            <div className='container'>
                <div className='background'>
                    {!showNoti ?
                        <div className='registerPanel socialRegister'>
                            <h1>회원가입</h1>
                            <h2><img className='logoImg' src={logoImg} alt="로고" />공정 모니터링 시스템</h2>
                            <ProviderInfo provider={provider} />
                            <form className={'registerForm'} onChange={onChange} onSubmit={onSubmit} autoComplete='off'>
                                <div className={'inputsArea hasProvider'}>
                                    <div className='emailInputArea'>
                                        <div className={userEmailError !== false ? `userEmail inputArea error ${userEmailError}` : 'userEmail inputArea'}>
                                            <input type='email' name="userEmail" placeholder='이메일' autoComplete="off" onBlur={onBlurEmail} defaultValue={tempEmail} />
                                            <span className='errMsgSpan dupEmailError'>이미 가입되어 있는 이메일입니다. 다시 확인해주세요.</span>
                                            <span className={'errMsgSpan invalidEmailError'}>올바르지 않은 이메일 형식입니다.</span>
                                            <span className={'errMsgSpan noValueError'}>이메일을 입력하세요.</span>
                                            <span className={formData.userEmail.length > 0 ? 'emailDupChkPassed visible' : 'emailDupChkPassed invisible'}>사용 가능한 이메일입니다.</span>
                                        </div>
                                        <button className={'dupChkBtn'} onClick={onChkDupEmail} disabled={dupChkComplete}>중복확인</button>
                                    </div>


                                    <div className={userNameError ? 'inputArea error' : 'inputArea'}>
                                        <input type='text' name='userName' placeholder='이름' disabled={provider !== null} defaultValue={provider !== null ? formData.userName : ''} />
                                    </div>
                                    <div className={userCompanyError ? 'inputArea error' : 'inputArea'}>
                                        <input type='text' name='userCompany' placeholder='소속' />
                                    </div>
                                </div>
                                <div className='btnsArea'>
                                    <input className='registerBtn' type='submit' value={"제출"} disabled={userEmail.length === 0 || userName.length === 0 || userCompany.length === 0 || userEmailError || userNameError || userCompanyError} />
                                    {/* <Link className='cancelBtn' to={'/'}>취소</Link> */}
                                    <button className='cancelBtn' onClick={onCancel}>취소</button>
                                </div>
                            </form>
                        </div>
                        : <AuthMailInfo userEmail={userEmail} />
                    }
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}

function ProviderInfo({ provider }) {
    let img;
    let name;
    switch (provider) {
        case 'naver':
            img = naverLogin;
            name = '네이버';
            break;
    };
    return (
        <div className='providerInfoArea'>
            <img src={img} alt={name} />
            <p>{`${name} 로그인을 선택하셨습니다.`}<br />회원가입을 완료하기 위해서 추가정보를 입력해주세요.</p>
        </div>
    )
}

export default SocialRegisterPage;