import axios from 'axios';
import React, { useEffect } from 'react';
import { API_URL } from '../../config/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogin } from '../../reducer/authentication';

function NaverLoginReq(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const userEmail = searchParams.get("userEmail");
    const onLogin = (data) => dispatch(setLogin(data));
    async function onReqLogin() {
        await axios.post(`${API_URL}/monitoring-manage/naverLoginReq`, { userEmail }).then(res => {
            console.log(res);
            onLogin(res.data);
            navigate(res.data.userRank === 1 ? '/home/members' : '/home/data/sumData');
        }).catch(err => console.error(err));
    };
    useEffect(() => {
        onReqLogin();
    }, [])
    return (
        <div />
    );
}

export default NaverLoginReq;