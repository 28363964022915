import React, { useEffect, useState } from 'react';
import CustomCheckbox from '../../../components/CustomCheckbox';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import DataModelTDs from './DataModelTDs';
import { CustomDatePicker } from '../../../components/CustomDatePicker';

function SumDataTbodyRow({
    index, item,
    chkedData, setChkedData,
    onChangeDateValue,
    onChangeValue,
    onChangeModel,
    modelTypeArr,
    resetting,
    setResetting,
    userIsNormal,
}) {
    const [formData, setFormData] = useState({
        ...item,
        materialXGap: Math.round(parseFloat(item.materialXGap) * 1000) / 10,
        materialYGap: Math.round(parseFloat(item.materialYGap) * 1000) / 10,
        peakGap: Math.round(parseFloat(item.peakGap) * 1000) / 10,
        sizeLeftGap: Math.round(parseFloat(item.sizeLeftGap) * 1000) / 10,
        sizeRightGap: Math.round(parseFloat(item.sizeRightGap) * 1000) / 10
    });
    const [inputEdited, setInputEdited] = useState(false);
    const navigate = useNavigate();
    const dataNgFactorOpt = [
        { label: '없음', value: '' },
        { label: '원자재 - X', value: '원자재 - X' },
        { label: '원자재 - Y', value: '원자재 - Y' },
        { label: '하중 - 피크', value: '하중 - 피크' },
        { label: '하중 - 이상패턴', value: '하중 - 이상패턴' },
        { label: '수치 - 좌', value: '수치 - 좌' },
        { label: '수치 - 우', value: '수치 - 우' },
    ];

    const dataDecisionOpt = [
        { label: "OK", value: "OK" },
        { label: "NG", value: "NG" },
    ];

    const onChangeInput = e => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    };
    const onBlurInput = e => {
        if (inputEdited && (item[e.target.name] !== formData[e.target.name])) onChangeValue(index, e.target.name, e.target.value);
    };

    const onClickMaterialDetail = e => {
        e.preventDefault();
        navigate(`/home/data/monitoring?name=material&dataId=${item.materialId}`);
    };
    const onClickLoadDetail = e => {
        e.preventDefault();
        navigate(`/home/data/monitoring?name=load&dataId=${item.loadId}`);
    };
    const onClickSizeDetail = e => {
        e.preventDefault();
        navigate(`/home/data/monitoring?name=size&dataId=${item.sizeId}`);
    };

    useEffect(() => {
        setFormData({
            ...item,
            materialXGap: Math.round(parseFloat(item.materialXGap) * 1000) / 10,
            materialYGap: Math.round(parseFloat(item.materialYGap) * 1000) / 10,
            peakGap: Math.round(parseFloat(item.peakGap) * 1000) / 10,
            sizeLeftGap: Math.round(parseFloat(item.sizeLeftGap) * 1000) / 10,
            sizeRightGap: Math.round(parseFloat(item.sizeRightGap) * 1000) / 10
        })
    }, [item]);

    useEffect(() => {
        setInputEdited(true);
    }, [setFormData]);

    useEffect(() => {
        if (!resetting) {
            setFormData({
                ...item,
                materialXGap: Math.round(parseFloat(item.materialXGap) * 1000) / 10,
                materialYGap: Math.round(parseFloat(item.materialYGap) * 1000) / 10,
                peakGap: Math.round(parseFloat(item.peakGap) * 1000) / 10,
                sizeLeftGap: Math.round(parseFloat(item.sizeLeftGap) * 1000) / 10,
                sizeRightGap: Math.round(parseFloat(item.sizeRightGap) * 1000) / 10
            });
        }
    }, [resetting]);

    return (
        <tr key={index}>
            <td className='dataChkbox'>
                <CustomCheckbox
                    id={`checkbox_${index}`}
                    checked={chkedData.includes(`${item.dataId}`)}
                    onChange={e => {
                        if (e.target.checked) {
                            setChkedData([...chkedData, e.target.value]);
                        } else {
                            setChkedData(chkedData.filter(el => el !== e.target.value));
                        };
                    }}
                    value={item.dataId}
                />
            </td>
            <td className='dataTime'>
                <CustomDatePicker
                    data={new Date(item.time)}
                    setData={value => onChangeDateValue(index, value)}
                    isDisabled={userIsNormal}
                />
            </td>
            <td className='dataDecision'>
                <Select
                    options={dataDecisionOpt}
                    value={dataDecisionOpt.find(el => el.value === item.decision)}
                    onChange={sel => onChangeValue(index, 'decision', sel.value)}
                    isSearchable={false}
                    classNames={{
                        control: (state) => 'control',
                        container: (state) => 'selectBox',
                        dropdownIndicator: (state) => 'arrow',
                        menu: (state) => 'options',
                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                        singleValue: (state) => 'singleVal',
                        valueContainer: (state) => 'valueContainer',
                    }}
                    components={{
                        DropdownIndicator: (state) => null,
                        IndicatorSeparator: (state) => null,
                    }}
                    openMenuOnClick={!userIsNormal}
                />
            </td>
            <DataModelTDs
                index={index}
                modelId={item.modelId}
                modelTypes={modelTypeArr}
                modelType={item.model.modelType}
                modelName={item.model.modelName}
                modelNo={item.model.modelNo}
                onChangeValue={onChangeModel}
                userIsNormal={userIsNormal}
            />
            <td className='dataCount'>
                <input
                    className='sumDataInput'
                    type='number'
                    name='count'
                    value={formData.count}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    disabled={userIsNormal}
                />
            </td>
            <td className='dataMaterialX'>
                <input
                    className='sumDataInput'
                    type='number'
                    name='materialX'
                    value={formData.materialX <= -99999.99999 ? "" : formData.materialX}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    disabled={userIsNormal}
                />
            </td>
            <td className='dataMaterialXGap'>
                <div className='percentageDiv'>
                    <input
                        className='sumDataInput'
                        type='number'
                        name='materialXGap'
                        min={0}
                        value={formData.materialXGap}
                        onInput={(e) => {
                            if (e.target.value < 0) e.target.value = 0;
                        }}
                        onChange={onChangeInput}
                        onBlur={onBlurInput}
                        disabled={userIsNormal}
                    />
                    <span>%</span>
                </div>
            </td>
            <td className='dataMaterialY'>
                <input
                    className='sumDataInput'
                    type='number'
                    name='materialY'
                    value={formData.materialY <= -99999.99999 ? "" : formData.materialY}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    disabled={userIsNormal}
                />
            </td>
            <td className='dataMaterialYGap'>
                <div className='percentageDiv'>
                    <input
                        className='sumDataInput'
                        type='number'
                        name='materialYGap'
                        min={0}
                        value={formData.materialYGap}
                        onInput={(e) => {
                            if (e.target.value < 0) e.target.value = 0;
                        }}
                        onChange={onChangeInput}
                        onBlur={onBlurInput}
                        disabled={userIsNormal}
                    />
                    <span>%</span>
                </div>
            </td>
            <td className='dataMaterialDetail detailCol'>
                <button onClick={onClickMaterialDetail} disabled={!item.materialId}>상세</button>
            </td>
            <td className='dataPeak'>
                <input
                    className='sumDataInput'
                    type='number'
                    name='peak'
                    value={formData.peak <= -99999.99999 ? "" : formData.peak}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    disabled={userIsNormal}
                />
            </td>
            <td className='dataPeakGap'>
                <div className='percentageDiv'>
                    <input
                        className='sumDataInput'
                        type='number'
                        name='peakGap'
                        min={0}
                        value={formData.peakGap}
                        onInput={(e) => {
                            if (e.target.value < 0) e.target.value = 0;
                        }}
                        onChange={onChangeInput}
                        onBlur={onBlurInput}
                        disabled={userIsNormal}
                    />
                    <span>%</span>
                </div>
            </td>
            <td className='dataAbnormalPattern'>
                <Select
                    options={[{ label: '있음', value: true }, { label: '없음', value: false }]}
                    value={item.abnormalPattern ? { label: '있음', value: true } : { label: '없음', value: false }}
                    onChange={sel => onChangeValue(index, 'abnormalPattern', sel.value)}
                    isSearchable={false}
                    openMenuOnClick={!userIsNormal}
                    classNames={{
                        control: (state) => 'control',
                        container: (state) => 'selectBox',
                        dropdownIndicator: (state) => 'arrow',
                        menu: (state) => 'options',
                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                        singleValue: (state) => 'singleVal',
                        valueContainer: (state) => 'valueContainer',
                    }}
                    components={{
                        DropdownIndicator: (state) => null,
                        IndicatorSeparator: (state) => null,
                    }}
                />
            </td>
            <td className='dataLoadDetail detailCol'>
                <button onClick={onClickLoadDetail} disabled={!item.loadId}>상세</button>
            </td>
            <td className='dataSizeLeft'>
                <input
                    className='sumDataInput'
                    type='number'
                    name='sizeLeft'
                    value={formData.sizeLeft <= -99999.99999 ? "" : formData.sizeLeft}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    disabled={userIsNormal}
                />
            </td>
            <td className='dataSizeLeftGap'>
                <div className='percentageDiv'>
                    <input
                        className='sumDataInput'
                        type='number'
                        name='sizeLeftGap'
                        min={0}
                        value={formData.sizeLeftGap}
                        onInput={(e) => {
                            if (e.target.value < 0) e.target.value = 0;
                        }}
                        onChange={onChangeInput}
                        onBlur={onBlurInput}
                        disabled={userIsNormal}
                    />
                    <span>%</span>
                </div>
            </td>
            <td className='dataSizeRight'>
                <input
                    className='sumDataInput'
                    type='number'
                    name='sizeRight'
                    value={formData.sizeRight <= -99999.99999 ? "" : formData.sizeRight}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    disabled={userIsNormal}
                />
            </td>
            <td className='dataSizeRightGap'>
                <div className='percentageDiv'>
                    <input
                        className='sumDataInput'
                        type='number'
                        name='sizeRightGap'
                        min={0}
                        value={formData.sizeRightGap}
                        onInput={(e) => {
                            if (e.target.value < 0) e.target.value = 0;
                        }}
                        onChange={onChangeInput}
                        onBlur={onBlurInput}
                        disabled={userIsNormal}
                    />
                    <span>%</span>
                </div>
            </td>
            <td className='dataSizeDetail detailCol'>
                <button onClick={onClickSizeDetail} disabled={!item.sizeId}>상세</button>
            </td>
            <td className='dataNgFactor'>
                <Select
                    options={dataNgFactorOpt}
                    value={dataNgFactorOpt.find(el => {
                        const itemNgFactor = item.ngFactor ?? '';
                        return el.value === itemNgFactor
                    })}
                    openMenuOnClick={!userIsNormal}
                    onChange={sel => onChangeValue(index, 'ngFactor', sel.value)}
                    isSearchable={false}
                    classNames={{
                        control: (state) => 'control',
                        container: (state) => 'selectBox',
                        dropdownIndicator: (state) => 'arrow',
                        menu: (state) => 'options',
                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                        singleValue: (state) => 'singleVal',
                        valueContainer: (state) => 'valueContainer',
                    }}
                    components={{
                        DropdownIndicator: (state) => null,
                        IndicatorSeparator: (state) => null,
                    }}
                />
            </td>
            <td className='dataComment'>
                <input
                    className='sumDataInput'
                    name='comment'
                    value={formData.comment ?? ''}
                    onChange={onChangeInput}
                    onBlur={onBlurInput}
                    disabled={userIsNormal}
                />
            </td>
            <td className='dataDetail'><button className='detailBtn' onClick={e => {
                e.preventDefault();
                navigate(`/home/data/sumData/detail/${item.dataId}`)
            }}
                // disabled={userIsNormal}
            >상세</button></td>
        </tr>
    );
}

export default SumDataTbodyRow;