import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { API_URL } from '../../config/constants';

function ResendAuthMail(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    const email = searchParams.get("email");
    async function onRequestResending() {
        await axios.post(`${API_URL}/monitoring-manage/resendAuthMail`, { userEmail: email }).then((res) => {
            console.log(res.data);
            if (res.data.success === true) {
                window.alert('인증 메일 재전송 성공')
                window.close();
            } else {
                // 인증실패
                window.alert('인증 메일 재전송에 실패했습니다. 로그인 정보를 확인하세요.')
                window.close();
            }

        }).catch(err => console.error(err));
    };

    useEffect(() => {
        onRequestResending();
    }, []);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#FFF',
        }} />
    );
}

export default ResendAuthMail;