import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { API_URL } from '../../config/constants';
import './VerifyEmail.scss';

function VerifyEmail(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [success, setSuccess] = useState(null);

    const email = searchParams.get("email");
    const token = searchParams.get("token");

    async function onRequestVerifying() {
        await axios.post(`${API_URL}/monitoring-manage/verifyEmail`, { email, token }).then((res) => {
            console.log(res.data);
            if (res.data.success === true) {
                setSuccess(true);
                // window.close();
            } else {
                // 인증실패
                setSuccess(false);
                // window.close();
            }
        }).catch(err => console.error(err));
    }

    function onClick(e) {
        e.preventDefault();
        window.close();
    }

    useEffect(() => {
        onRequestVerifying();
    }, []);
    return (
        <div className='fullBg' style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#FFF',
        }}>
            {
                success === null ? null :
                    <div className={'verifyEmailPopup'}>
                        <div className="msgArea">
                            {
                                success === true ?
                                    <React.Fragment>
                                        <p>인증성공되었습니다.<br />
                                            조직관리자의 승인을 대기 중입니다.<br />
                                            승인 완료 시 로그인이 완료됩니다.</p>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <p>이메일 인증이 완료되지 않았습니다.<br />다시 시도해 주십시오.</p>
                                    </React.Fragment>
                            }
                        </div>
                        <button className="okBtn" onClick={onClick}>확인</button>
                    </div>
            }
        </div>
    );
}

export default VerifyEmail;