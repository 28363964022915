import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ChangePwModal from './ChangePwModal';
import FadeOutNoti from '../components/FadeOutNoti';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import YouWereEditing from '../components/YouWereEditing';

function EditMyData(props) {
    const { myInfo, userRanks } = useOutletContext();
    const navigate = useNavigate();
    const initData = Object.assign({}, myInfo);
    const [formData, setFormData] = useState({
        id: 0,
        userEmail: '',
        userName: '',
        userCompany: '',
        userRank: 0,
    })
    const [nowEditing, setNowEditing] = useState('');
    const [isChgModalOpen, setChgModalIsOpen] = useState(false);
    const [isCancelModalOpen, setCancelModalIsOpen] = useState(false);
    const [isSaving, setIsSaving] = useState({
        success: false,
        notiPop: false
    });
    const onRequestClose = e => {
        e.preventDefault();
        setChgModalIsOpen(false);
        setCancelModalIsOpen(false);
    }
    const onChange = e => {
        e.preventDefault();
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        const { id, ...rest } = formData;
        await axios.post(`${API_URL}/monitoring-manage/updateUser`, [{ id: formData.id, newValue: rest }]).then(res => {
            setIsSaving({
                success: true,
                notiPop: true,
            })
            setTimeout(() => {
                window.location.href = "/home/mypage"
            }, 2500);
        }).catch(e => console.error(e));
    };
    const onCancel = e => {
        e.preventDefault();
        if (initData.userEmail === formData.userEmail && initData.userName === formData.userName && initData.userCompany === formData.userCompany && initData.userRank === formData.userRank) {
            navigate('/home/mypage');
        } else {
            setCancelModalIsOpen(true);
        }
    };

    const myRankObj = userRanks.filter(item => item.rank === myInfo.userRank)[0]
    const myRankStr = `${myRankObj.rank} (${myRankObj.name})`;

    const onFocus = e => setNowEditing(e.target.name);
    const onBlur = e => setNowEditing('');

    useEffect(() => {
        console.log('edit측')
        setFormData({ ...myInfo });
    }, [myInfo, setFormData])
    return (
        <React.Fragment>
            <div className='editMyInfo'>
                <p className='path'>내정보<span className='below'>{'>'}</span>내정보수정</p>
                <h2 className='pageTitle'>내 정보</h2>
                <div className='pageContent'>
                    <div className='myInfoTblArea'>
                        <button className='editMyInfoBtn' onClick={onCancel}>내 정보 수정</button>
                        <form onChange={onChange} onSubmit={onSubmit}>

                            <table className='myInfoTbl'>
                                <tbody>
                                    <tr className={initData.userEmail === formData.userEmail ? '' : 'edited'}>
                                        <td className='labelTd'>
                                            E-mail
                                        </td>
                                        <td className='infoTd'>
                                            <input name='userEmail' type='email' defaultValue={myInfo.userEmail} onFocus={onFocus} onBlur={onBlur} />
                                            <div className={nowEditing === "userEmail" ? 'isEditing visible' : 'isEditing invisible'} />
                                        </td>
                                    </tr>
                                    <tr style={{ height: '31px' }} />
                                    <tr>
                                        <td className='labelTd'>
                                            비밀번호
                                        </td>
                                        <td className='infoTd'>
                                            <span>************</span>
                                            <button className='changePwBtn' onClick={e => { e.preventDefault(); setChgModalIsOpen(true); }}>변경</button>
                                        </td>
                                    </tr>
                                    <tr style={{ height: '31px' }} />
                                    <tr className={initData.userName === formData.userName ? '' : 'edited'}>
                                        <td className='labelTd'>
                                            이　름
                                        </td>
                                        <td className='infoTd'>
                                            <input name='userName' type='text' defaultValue={myInfo.userName} onFocus={onFocus} onBlur={onBlur} />
                                            <div className={nowEditing === "userName" ? 'isEditing visible' : 'isEditing invisible'} />
                                        </td>
                                    </tr>
                                    <tr style={{ height: '31px' }} />
                                    <tr className={initData.userCompany === formData.userCompany ? '' : 'edited'}>
                                        <td className='labelTd'>
                                            소　속
                                        </td>
                                        <td className='infoTd'>
                                            <input name='userCompany' type='text' defaultValue={myInfo.userCompany} onFocus={onFocus} onBlur={onBlur} />
                                            <div className={nowEditing === "userCompany" ? 'isEditing visible' : 'isEditing invisible'} />
                                        </td>
                                    </tr>
                                    <tr style={{ height: '31px' }} />
                                    <tr className={initData.userRank === formData.userRank ? '' : 'edited'}>
                                        <td className='labelTd'>
                                            레　벨
                                        </td>
                                        <td className='infoTd myRank'>
                                            {myRankStr}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='btnsArea'>
                                <input type='submit' className='formBtn submitBtn' value={"확인"} />
                                <button className='formBtn cancelBtn' onClick={onCancel}>취소</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ChangePwModal
                isOpen={isChgModalOpen}
                onRequestClose={onRequestClose}
                trySubmit={(bool) => {
                    setIsSaving({
                        success: bool,
                        notiPop: true,
                    });
                }}
            />
            <YouWereEditing
                isOpen={isCancelModalOpen}
                onRequestClose={onRequestClose}
                onSubmit={e => { e.preventDefault(); navigate('/home/mypage') }}
            />
            <FadeOutNoti showNoti={isSaving.notiPop} onClose={() => { setIsSaving({ success: false, notiPop: false }); }} msg={isSaving.success ? '변경 사항이 저장되었습니다.' : '문제가 발생하여 처리하지 못했습니다.'} bg={true} />
        </React.Fragment>
    );
}

export default EditMyData;