import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import Select from 'react-select';
import arrowIconImg from '../../images/filledArrowIcon.png';
import createIcon from '../../images/createIcon.png';


function EditDataStructure(props) {
    const { dataStructures, userRanks } = useOutletContext();
    const [formData, setFormData] = useState({
        setName: '',
        noRecords: 0,
        name: '',
        samplingRate: 0,
        topicMQ: '',
        company: '',
        dataLevel: 1,
        comment: '',
    });
    const [changed, setChanged] = useState(false);
    const [sensorDatas, setSensorDatas] = useState([]);
    const param = useParams();
    const { id } = param;
    const navigate = useNavigate();
    const data = dataStructures.filter(x => x.dataStructureId === parseInt(id))[0];
    console.log(data)
    const originData = {
        setName: data.setName,
        noRecords: data.noRecords,
        name: data.name,
        samplingRate: data.samplingRate,
        topicMQ: data.topicMQ,
        company: data.company,
        dataLevel: data.dataLevel,
        comment: data.comment,
    };
    const dataName = data.dataName.split(';');
    const type = data.type.split(';');
    const gain = data.gain.split(';').map(el => parseFloat(el));
    const offset = data.offset.split(';').map(el => parseFloat(el));
    const dimension = data.dimension.split(';');
    const locations = data.locations.split(';');
    const refValue = data.RefValue.refValue.split(';');
    const lowErrRng = data.RefValue.lowErrRng.split(';');
    const highErrRng = data.RefValue.highErrRng.split(';');

    const sensors = dataName.map((item, idx) => ({ dataName: item, type: type[idx], gain: gain[idx], offset: offset[idx], dimension: dimension[idx], locations: locations[idx], refValue: refValue[idx], lowErrRng: lowErrRng[idx], highErrRng: highErrRng[idx] }));
    const onChange = e => {
        e.preventDefault();
        const tgtName = e.target.name;
        if (tgtName === 'noRecords') {
            setFormData({ ...formData, [tgtName]: parseInt(e.target.value) });
        } else if (tgtName === 'samplingRate') {
            setFormData({ ...formData, [tgtName]: parseInt(e.target.value) });
        } else {
            setFormData({ ...formData, [tgtName]: e.target.value });
        }
    };

    const onChangeSensorData = (e, idx, tgtName) => {
        const sensorData = { ...sensorDatas[idx], [tgtName]: e.target.value };
        const copiedSensorsArr = [...sensorDatas];
        copiedSensorsArr[idx] = sensorData;
        // setFormData({ ...formData, sensors: copiedSensorsArr });
        setSensorDatas(copiedSensorsArr);
    };
    const onAddSensor = e => {
        e.preventDefault();
        const newSensorData = {
            dataName: '',
            type: '',
            gain: 0,
            offset: 0,
            dimension: '',
            locations: '',
            refValue: 0,
            lowErrRng: 0,
            highErrRng: 0,
        }
        const copiedSensorsArr = [...sensorDatas];
        copiedSensorsArr.push(newSensorData);
        setSensorDatas(copiedSensorsArr);
    };
    const onDeleteSensorData = (e, idx) => {
        e.preventDefault();
        const copiedSensorsArr = [...sensorDatas];
        copiedSensorsArr.splice(idx, 1);
        setSensorDatas(copiedSensorsArr);
    };
    const setNameRef = useRef();
    const spanRef = useRef();
    const optionArr = userRanks.map(item => ({ label: item.rank, value: item.rank }));

    const chgMonit = () => {
        const changed = JSON.stringify(formData) !== JSON.stringify(originData) || JSON.stringify(sensorDatas) !== JSON.stringify(sensors);
        setChanged(changed);
    };

    const onCancel = e => {
        navigate('/home/dataStructure')
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // 센서 데이터 변환
        const nameArr = sensorDatas.map(el => el.dataName).join(';');
        const typeArr = sensorDatas.map(el => el.type).join(';');
        const gainArr = sensorDatas.map(el => el.gain).join(';');
        const offsetArr = sensorDatas.map(el => el.offset).join(';');
        const dimArr = sensorDatas.map(el => el.dimension).join(';');
        const locArr = sensorDatas.map(el => el.locations).join(';');
        const refValue = sensorDatas.map(el => el.refValue).join(';');
        const lowErrRng = sensorDatas.map(el => el.lowErrRng).join(';');
        const highErrRng = sensorDatas.map(el => el.highErrRng).join(';');
        // API 요청
        await axios.post(`${API_URL}/monitoring-manage/updateDataStructure`, {
            ...formData,
            dataStructureId: parseInt(id),
            dataName: nameArr,
            type: typeArr,
            gain: gainArr,
            offset: offsetArr,
            dimension: dimArr,
            locations: locArr,
            refValue: {
                refValue,
                lowErrRng,
                highErrRng,
            }
        }).then(res => {
            if (!res.data.success) {
                setNameRef.current.focus();
                console.log(res.data.result)
                if (res.data.result === 'dupSetName') {
                    spanRef.current.textContent = "DB 내 중복된 setName이 존재합니다."
                } else {
                    spanRef.current.textContent = "DB 내 중복된 테이블이 존재합니다."
                }
            } else {
                window.location.href = '/home/dataStructure';
            }
        }).catch(e => { console.error(e) });
    };
    const printSensorDatas = useCallback(() => {
        console.log(sensorDatas)
        return sensorDatas.map((el, idx, arr) => {
            return (
                <li className='col' key={idx}>
                    <div>
                        <ul>
                            <li className='printedInputLi'>
                                <input name='dataName' value={el.dataName || ""} onChange={e => onChangeSensorData(e, idx, 'dataName')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='type' value={el.type || ""} onChange={e => onChangeSensorData(e, idx, 'type')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='gain' type='number' value={el.gain || 0} onChange={e => onChangeSensorData(e, idx, 'gain')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='offset' type='number' value={el.offset || 0} onChange={e => onChangeSensorData(e, idx, 'offset')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='dimension' value={el.dimension || ""} onChange={e => onChangeSensorData(e, idx, 'dimension')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='locations' value={el.locations || ""} onChange={e => onChangeSensorData(e, idx, 'locations')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='refValue' type='number' value={el.refValue || 0} onChange={e => onChangeSensorData(e, idx, 'refValue')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='lowErrRng' type='number' value={el.lowErrRng || 0} onChange={e => onChangeSensorData(e, idx, 'lowErrRng')} />
                            </li>
                            <li className='printedInputLi'>
                                <input name='highErrRng' type='number' value={el.highErrRng || 0} onChange={e => onChangeSensorData(e, idx, 'highErrRng')} />
                            </li>
                        </ul>
                        <button className='delSD' onClick={e => onDeleteSensorData(e, idx)}><div /></button>
                    </div>
                </li>
            )
        })
    }, [sensorDatas])

    useEffect(() => {

        setFormData({
            setName: data.setName,
            noRecords: data.noRecords,
            name: data.name,
            samplingRate: data.samplingRate,
            topicMQ: data.topicMQ,
            company: data.company,
            dataLevel: data.dataLevel,
            comment: data.comment,
        });
        setSensorDatas([...sensors])
    }, [])
    useEffect(() => {
        chgMonit();
    }, [formData, sensorDatas])
    return (
        <div className='editDataStructure'>
            <p className='path'>데이터 구조 관리<span className='below'>{'>'}</span>데이터 구조<span className='below'>{'>'}</span>데이터수정</p>
            <h2 className='pageTitle'>데이터 수정</h2>
            <form onSubmit={onSubmit}>
                <ul className='formTbl'>
                    <li className='col'><ul>
                        <li className='formTh setName'>setName</li>
                        <li className='setName'><input name={'setName'} onChange={onChange} value={formData.setName || ""} ref={setNameRef} /><span ref={spanRef} /></li>
                    </ul></li>
                    <li className='col'><ul>
                        <li className='formTh noRecord'>noRecords</li>
                        <li className='noRecord'><input name={'noRecords'} type='number' onChange={onChange} value={formData.noRecords || 0} min={0} /></li>
                    </ul></li>
                    <li className='col'><ul>
                        <li className='formTh name'>name</li>
                        <li className='name'><input name={'name'} onChange={onChange} value={formData.name || ""} /></li>
                    </ul></li>
                    <li className='col dataRow'>
                        {/* 센서 데이터 테이블 */}
                        <ul className='dataRowTbl'>
                            <li className='col'>
                                <ul className='dataRowTh'>
                                    <li className='col semiTtl'><ul>
                                        <li className='sensorData'>센서데이터</li>
                                        <li className='refVal'>기준값 관리</li>
                                    </ul></li>
                                    <li className='col'>
                                        <ul>
                                            <li className='th'>dataName</li>
                                            <li className='th'>type</li>
                                            <li className='th'>gain</li>
                                            <li className='th'>offset</li>
                                            <li className='th'>dimension</li>
                                            <li className='th'>locations</li>
                                            <li className='th'>기준값</li>
                                            <li className='th'>오차 하한</li>
                                            <li className='th'>오차 상한</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            {
                                printSensorDatas()
                            }
                            <li className='col add'><button onClick={onAddSensor}><img src={createIcon} alt='추가' /></button></li>
                        </ul>
                        {/* 여기까지 */}
                    </li>
                    <li className='col'><ul>
                        <li className='formTh'>samplingRate</li>
                        <li><input name={'samplingRate'} type='number' onChange={onChange} value={formData.samplingRate || 0} min={0} /></li>
                    </ul></li>
                    <li className='col'><ul>
                        <li className='formTh'>topicMQ</li>
                        <li><input name={'topicMQ'} onChange={onChange} value={formData.topicMQ || ""} /></li>
                    </ul></li>
                    <li className='col'><ul>
                        <li className='formTh'>company</li>
                        <li><input name={'company'} onChange={onChange} value={formData.company || ""} /></li>
                    </ul></li>
                    <li className='col'><ul>
                        <li className='formTh'>dataLevel</li>
                        <li className='selectLi'>
                            {/* <input name={'dataLevel'} type='number' onChange={onChange} value={formData.dataLevel} /> */}
                            <Select
                                options={optionArr}
                                onChange={sel => { setFormData({ ...formData, dataLevel: sel.value }); }}
                                value={optionArr.find(el => el.value === formData.dataLevel)}
                                isSearchable={false}
                                maxMenuHeight={'225px'}
                                classNames={{
                                    control: (state) => 'control',
                                    container: (state) => 'selectBox',
                                    dropdownIndicator: (state) => 'arrow',
                                    menu: (state) => 'options',
                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                    singleValue: (state) => 'singleVal',
                                    valueContainer: (state) => 'valueContainer',
                                }}
                                components={{
                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                    IndicatorSeparator: (state) => null,
                                }}
                            />
                        </li>
                    </ul></li>
                    <li className='col'><ul>
                        <li className='formTh'>comment</li>
                        <li><input name={'comment'} onChange={onChange} value={formData.comment || ""} placeholder='(선택) 코멘트를 입력해주세요.' /></li>
                    </ul></li>
                </ul>
                <div className='btnsArea'>
                    <input className='formBtn submitBtn' type='submit' disabled={!changed} value={'저장'} />
                    <button className='formBtn cancelBtn' onClick={onCancel}>취소</button>
                </div>
            </form>
        </div>
    );
}

export default EditDataStructure;