import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import './members.scss';
import { useSelector } from 'react-redux';

function Members(props) {

    const [chkAll, setChkAll] = useState(false);
    const [chkedData, setChkedData] = useState([]);

    const { userRank } = useSelector((state) => state.session);

    useEffect(() => {
        if (userRank !== 1) window.location.href = '/';
    }, [userRank]);
    return (
        <div className='membersPage'>
            <Outlet context={{
                chkAll: chkAll,
                setChkAll: setChkAll,
                chkedData: chkedData,
                setChkedData: setChkedData,
            }} />
        </div>
    );
}

export default Members;