import React, { useEffect, useState } from 'react';
import ShowUserRank from './ShowUserRank';
import './userRank.scss';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router';
import { setLogin } from '../../../reducer/authentication';

function UserRank(props) {
    const { userIsNormal } = useOutletContext();
    const dispatch = useDispatch();
    const onLogout = () => dispatch(setLogin());
    const [userRankList, setUserRankList] = useState([]);
    async function getUserRanks() {
        const response = await axios.get(`${API_URL}/monitoring-manage/getUserRanks`);
        setUserRankList([...response.data]);
    }
    useEffect(() => {
        if (userIsNormal) onLogout();
    }, [userIsNormal]);
    useEffect(() => {
        getUserRanks();
    }, [])
    const { userRank } = useSelector((state) => state.session);
    const navigate = useNavigate();
    if (userRank !== 1) navigate('/');
    return (
        <React.Fragment>
            <ShowUserRank userRank={userRankList} />
        </React.Fragment>
    );
}

export default UserRank;