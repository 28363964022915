import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import './NewMembersModal.scss';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import closeIconImg from '../../images/closeIcon.png';
import arrowIconImg from '../../images/filledArrowIcon.png';
import { ConfirmContext } from '../components/ConfirmModal';
import Select from 'react-select';
import CustomCheckbox from '../components/CustomCheckbox';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.24)',
        zIndex: 98
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '15px',
        boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function NewMembersModal({
    data,
    userRanks,
    isOpen,
    onRequestClose,
    contentLabel,
    memberList,
    setMemberList,
}) {

    let subtitle;
    const [chkAll, setChkAll] = useState(false);
    const [chkedData, setChkedData] = useState([]);
    const [userData, setUserData] = useState([]);
    const dropdownRef = useRef([]);

    const { confirm } = useContext(ConfirmContext);

    useEffect(() => {
        setUserData([...data]);
    }, [data]);

    const onCheckElement = (checked, item) => {
        if (checked) {
            setChkedData([...chkedData, item]);
        } else {
            setChkedData(chkedData.filter(el => el !== item));
        };
    };
    const onCheckAll = (e) => {
        if (!chkAll) {
            setChkAll(true);
            setChkedData(data.map(item => item.id.toString()));
        } else {
            setChkAll(false);
            setChkedData([]);
        };
    };
    const onCheckOnAll = (e) => {
        setChkAll(true);
        setChkedData(data.map(item => item.id.toString()));
    };
    const onCheckOffAll = (e) => {
        e.preventDefault();
        setChkAll(false);
        setChkedData([]);
    };
    const onChangeRank = (sel, dataId) => {
        const foundIndex = userData.findIndex(item => item.id === dataId);
        const copiedArr = [...userData];
        copiedArr[foundIndex].userRank = sel.value;
        setUserData(copiedArr);
    };
    const onChangeApproval = async (sel, dataId) => {
        const foundIndex = userData.findIndex(item => item.id === dataId);
        const copiedArr = [...userData];
        const editedData = { ...copiedArr[foundIndex] };
        if (sel.value === 'approval') {
            // 승인 이벤트
            // 승인하시겠습니까? 팝업
            const result = await confirm('승인하시겠습니까?', '네', '아니오');
            if (result) {
                // 업데이트될 새로운 userRank를 정의
                let newRank = editedData.userRank === 9 ? 7 : editedData.userRank;
                // DATA수정을 요청하는 API 요청
                await axios.post(`${API_URL}/monitoring-manage/updateUser`, [{ id: editedData.id, newValue: { userRank: newRank } }]).then(res => {
                    console.log(res.data);
                    const copiedUserArr = [...memberList];
                    const filteredUserArr = copiedUserArr.filter(item => item.id !== editedData.id);
                    setMemberList([...filteredUserArr, ...res.data].sort((a, b) => a.id - b.id))
                    dropdownRef.current.forEach(el => el.value = 7);
                    sel.value = 'unselected';
                }).catch(err => console.error(err));
            } else {
                sel.value = 'unselected';
                return false;
            }

        } else if (sel.value === 'rejection') {
            // 거부 이벤트
            // 거부하시겠습니까? 팝업
            const result = await confirm('거부하시겠습니까?', '네', '아니오');
            if (result) {
                // 현재 데이터의 userRank를 10으로 변경
                await axios.post(`${API_URL}/monitoring-manage/updateUser`, [{ id: editedData.id, newValue: { userRank: 10 } }]).then(res => {
                    console.log(res.data);
                    // 상태관리중인 DATA 배열에서 수정된 DATA 삭제
                    const copiedUserArr = [...memberList];
                    const filteredUserArr = copiedUserArr.filter(item => item.id !== editedData.id);
                    setMemberList([...filteredUserArr, ...res.data].sort((a, b) => a.id - b.id))
                    sel.value = 'unselected';
                }).catch(err => console.error(err));
            } else {
                sel.value = 'unselected';
                return false;
            }
        } else {
            return false;
        }
    };
    const onClickAppBtn = async (e) => {
        e.preventDefault();
        if (chkedData.length === 0) return false;
        const copiedArr = [...userData];

        const result = await confirm('승인하시겠습니까?', '네', '아니오');
        if (result) {
            const chkedDataArr = [...chkedData];
            const reqArr = chkedDataArr.map(idNo => {
                const foundIndex = copiedArr.findIndex(item => item.id === parseInt(idNo));
                const editedData = { ...copiedArr[foundIndex] };
                let newRank = editedData.userRank === 9 ? 7 : editedData.userRank;
                return ({ id: idNo, newValue: { userRank: newRank } });
            });
            console.log(reqArr);
            await axios.post(`${API_URL}/monitoring-manage/updateUser`, reqArr).then(res => {
                console.log(res.data);
                // 상태관리중인 DATA 배열에서 수정된 DATA 삭제
                const copiedUserArr = [...memberList];
                const filteredUserArr = copiedUserArr.filter(item => !chkedDataArr.includes(item.id.toString()));
                setMemberList([...filteredUserArr, ...res.data].sort((a, b) => a.id - b.id));
                dropdownRef.current.forEach(item => item.value = 7);
            }).catch(err => console.error(err));
        } else {
            return false
        }
    };
    const onClickRejBtn = async (e) => {
        e.preventDefault();
        if (chkedData.length === 0) return false;
        const result = await confirm('거부하시겠습니까?', '네', '아니오');
        if (result) {
            const chkedDataArr = [...chkedData];
            const reqArr = chkedDataArr.map(item => ({ id: item, newValue: { userRank: 10 } }));
            // 선택된 데이터들의 userRank를 10으로 변경
            await axios.post(`${API_URL}/monitoring-manage/updateUser`, reqArr).then(res => {
                console.log(res.data);
                // 상태관리중인 DATA 배열에서 수정된 DATA 삭제
                const copiedUserArr = [...memberList];
                const filteredUserArr = copiedUserArr.filter(item => !chkedDataArr.includes(item.id.toString()));
                setMemberList([...filteredUserArr, ...res.data].sort((a, b) => a.id - b.id))
            }).catch(err => console.error(err));
        } else {
            return false
        }
    };
    const onCloseWithInit = (e) => {
        dropdownRef.current.forEach(item => item ? item.value = 7 : null);
        setChkAll(false);
        setChkedData([]);
        onRequestClose();
    }

    const rankOptionArr = userRanks.map(item => ({ label: item.rank, value: item.rank }));


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onCloseWithInit}
            style={customStyles}
            contentLabel={contentLabel}

        >
            <div className='modalContentArea newMembers'>
                <h2 className='modalTitle' ref={(_subtitle) => (subtitle = _subtitle)}>신규회원</h2>
                <button className='modalBtn closeBtn' onClick={onCloseWithInit}><img src={closeIconImg} alt={'창 닫기'} /></button>
                <div className='btnsArea'>
                    <div className='selectBtns'>
                        <button className='chkAllBtn' onClick={onCheckOnAll}>전체선택</button>
                        <button className='chkOffAllBtn' onClick={onCheckOffAll}>전체해제</button>
                    </div>
                    <div className='approvalBtns'>
                        <button onClick={onClickAppBtn}>승인</button>
                        <button onClick={onClickRejBtn}>거부</button>
                    </div>
                </div>
                <div className='tableWrapper'>
                    <table className='membersTable'>
                        <thead>
                            <tr>
                                <th className='userChkbox'>
                                    <div className='innerDiv'>
                                        <CustomCheckbox
                                            id={"NMM_checkbox_all"}
                                            checked={chkAll}
                                            onChange={onCheckAll}
                                            value={"all"}
                                        />
                                    </div>
                                </th>
                                <th className='userName'><div className='innerDiv'>이름</div></th>
                                <th className='userEmail'><div className='innerDiv'>이메일</div></th>
                                <th className='userCompany'><div className='innerDiv'>소속</div></th>
                                <th className='userRank'><div className='innerDiv'>레벨</div></th>
                                <th className='approval'><div className='innerDiv'>승인</div></th>
                            </tr>
                            <tr style={{ height: '12px' }} />
                        </thead>
                        <tbody>
                            {data.map((item, idx, arr) => {
                                return (
                                    <tr key={idx}>
                                        <td className='userChkbox'>
                                            <CustomCheckbox
                                                id={`NMM_checkbox_${item.id}`}
                                                checked={chkedData.includes(`${item.id}`)}
                                                onChange={e => { onCheckElement(e.target.checked, e.target.value); }}
                                                value={item.id}
                                            />
                                        </td>
                                        <td className='userName'>{item.userName}</td>
                                        <td className='userEmail'>{item.userEmail}</td>
                                        <td className='userCompany'>{item.userCompany}</td>
                                        <td className='userRank'>
                                            <div>
                                                <Select
                                                    options={rankOptionArr}
                                                    onChange={sel => onChangeRank(sel, item.id)}
                                                    defaultValue={rankOptionArr.find(el => el.value === 7)}
                                                    isSearchable={false}
                                                    cropWithEllipsis={true}
                                                    classNames={{
                                                        control: (state) => 'control',
                                                        container: (state) => 'selectBox',
                                                        dropdownIndicator: (state) => 'arrow',
                                                        menu: (state) => 'options',
                                                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                        singleValue: (state) => 'singleVal',
                                                        valueContainer: (state) => 'valueContainer',
                                                    }}
                                                    components={{
                                                        DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                        IndicatorSeparator: (state) => null,
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td className='approval'>
                                            <div>
                                                <Select
                                                    options={[{ label: '승인', value: 'approval' }, { label: '거부', value: 'rejection' }]}
                                                    onChange={sel => onChangeApproval(sel, item.id)}
                                                    defaultValue={{ label: '선택' }}
                                                    isSearchable={false}
                                                    cropWithEllipsis={true}
                                                    classNames={{
                                                        control: (state) => 'control',
                                                        container: (state) => 'selectBox',
                                                        dropdownIndicator: (state) => 'arrow',
                                                        menu: (state) => 'options',
                                                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                        singleValue: (state) => 'singleVal',
                                                        valueContainer: (state) => 'valueContainer',
                                                    }}
                                                    components={{
                                                        DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                        IndicatorSeparator: (state) => null,
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>

    );
}

export default NewMembersModal;