import React, { useState } from 'react';
import './MyPage.scss';
import { Outlet, Route, Routes } from 'react-router';
import useAsync from '../../hooks/useAsync';
import axios from 'axios';
import { API_URL } from '../../config/constants';
import Loading from '../components/Loading';

function MyPage(props) {
    async function getMyInfo() {
        const response = await axios.post(`${API_URL}/monitoring-manage/getMyInfo`);
        return response.data;
    }

    const state = useAsync(getMyInfo);
    const { loading, error, data: result } = state;
    if (loading) return <div className='myPage' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='myPage'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return null;
    return (
        <div className='myPage'>
            <Outlet context={{
                myInfo: result.user,
                userRanks: result.userRanks,
            }} />
        </div>
    );
}

export default MyPage;