import React, { useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

function ShowMyData(props) {
    const { myInfo, userRanks } = useOutletContext();
    const navigate = useNavigate();
    const onBack = e => {
        e.preventDefault();
        navigate('/home/data/sumData');
    };
    const myRank = userRanks.find(el => el.rank === myInfo.userRank);
    return (
        <div className='myInfo'>
            <p className='path'>내정보</p>
            <h2 className='pageTitle'>내 정보</h2>
            <div className='pageContent'>
                <div className='myInfoTblArea'>
                    <Link className='editMyInfoBtn' to={'/home/mypage/edit'}>내 정보 수정</Link>
                    <table className='myInfoTbl'>
                        <tbody>
                            <tr>
                                <td className='labelTd'>
                                    E-mail
                                </td>
                                <td className='infoTd'>
                                    {
                                        myInfo.userEmail
                                    }
                                </td>
                            </tr>
                            <tr style={{ height: '31px' }} />
                            <tr>
                                <td className='labelTd'>
                                    비밀번호
                                </td>
                                <td className='infoTd'>
                                    ************
                                </td>
                            </tr>
                            <tr style={{ height: '31px' }} />
                            <tr>
                                <td className='labelTd'>
                                    이　름
                                </td>
                                <td className='infoTd'>
                                    {
                                        myInfo.userName
                                    }
                                </td>
                            </tr>
                            <tr style={{ height: '31px' }} />
                            <tr>
                                <td className='labelTd'>
                                    소　속
                                </td>
                                <td className='infoTd'>
                                    {
                                        myInfo.userCompany
                                    }
                                </td>
                            </tr>
                            <tr style={{ height: '31px' }} />
                            <tr>
                                <td className='labelTd'>
                                    레　벨
                                </td>
                                <td className='infoTd'>
                                    {`${myRank.rank} (${myRank.name})`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='btnsArea'>
                        {/* <a href={`https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_ID}&redirect_uri=${API_URL}/monitoring-manage/linkAccountToNaver?userEmail=${myData.userEmail}`}>네이버 연동</a> */}
                        <button className='backBtn' onClick={onBack}>돌아가기</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShowMyData;