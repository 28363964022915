import React from 'react';
import Modal from 'react-modal';
import './YouWereEditing.scss';
import closeIconImg from '../../images/closeIcon.png';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.24)',
        zIndex: 98
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '18px',
        boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function YouWereEditing({ isOpen, onRequestClose, onSubmit }) {
    return (<Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel='나가시겠습니까?'
    >
        <div className='modalContentArea cancelEditData'>
            <button className='closeModal' onClick={onRequestClose}><img src={closeIconImg} alt={"창 닫기"} /></button>
            <div className='alertMsg'>
                <p>변경사항이 저장되지 않았습니다.</p>
                <p>이대로 나가시겠습니까?</p>
            </div>
            <div className='btnsArea'>
                <button className='exitBtn' onClick={onSubmit}>나가기</button>
                <button className='cancelBtn' onClick={onRequestClose}>취소</button>
            </div>
        </div>
    </Modal>);
}

export default YouWereEditing;