import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConfirmDialog } from './components/ConfirmModal';
import { Outlet, useLocation, useNavigate } from 'react-router';

function Root(props) {
    const loginState = useSelector((state) => state.session.login);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (loginState) {
        } else {
            if (location.pathname !== '/' && location.pathname !== "/register" && location.pathname !== "/socialRegister" && location.pathname !== "/verifyEmail" && location.pathname !== '/resendAuthMail' && location.pathname !== "/naverLoginReq" && location.pathname.split('/')[1] !== "notVerifiedYet" && location.pathname !== '/changePw') {
                navigate('/');
            };
        }
    }, [loginState])
    return (
        <React.Fragment>
            <ConfirmDialog>
                <Outlet />
            </ConfirmDialog>
        </React.Fragment>
    );
}

export default Root;