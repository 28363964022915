import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './FadeOutNoti.scss';

Modal.setAppElement('#root');


function FadeOutNoti({ showNoti, onClose, msg, bg = false }) {
    useEffect(() => {
        if (showNoti) {
            console.log('useEffect start')
            const disappear = setTimeout(() => {
                console.log('timeout 발동')
                onClose();
            }, 2700);
            return () => {
                clearTimeout(disappear);
            }
        }
    }, [showNoti])
    return (
        <Modal
            isOpen={showNoti}
            style={{
                overlay: {
                    backgroundColor: bg ? 'rgba(0,0,0,0.24)' : 'transparent',
                    zIndex: 99,
                },
                content: {
                    position: 'fixed',
                    top: '75px',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translateX(-50%)',
                    padding: 'none',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
                },
            }}
            onRequestClose={onClose}
            className={'notiWindow fadeoutNoti'}
            shouldCloseOnOverlayClick={false}
        >
            <div className='innerDiv'>
                <p>{msg}</p>
            </div>
        </Modal>
    );
}

export default FadeOutNoti;