import React from 'react';
import bglessLogo from '../../images/bglessLogo.png';
import './Loading.scss';

function Loading(props) {
    return (
        <div className='loadingPage'>
            <div className='loadingIconNInfo'>
                <img src={bglessLogo} alt="로딩중" />
                <div className='pArea'>
                    <p>LOADING</p>
                    <p>잠시만 기다려 주세요.</p>
                </div>
            </div>
        </div>
    );
}

export default Loading;