import React from 'react';
import Modal from 'react-modal';
import './LoginPageModal.scss';

Modal.setAppElement('#root');

const customStyle = {
    overlay: {
        backgroundColor: 'transparent'
        // zIndex: 98,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '10px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
    },
}

function NotApprovedYetPopup({ isOpen, onRequestClose }) {
    return (
        <Modal
            isOpen={isOpen}
            style={customStyle}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={false}
        >
            <div className='loginPageModal notApprovedYet'>
                <div className='msg'>
                    <p>조직관리자의 승인을 대기 중입니다.<br />승인 완료 시 로그인 가능합니다.</p>
                </div>
                <button className='goBackBtn' onClick={onRequestClose}>돌아가기</button>
            </div>
        </Modal>
    );
}

export default NotApprovedYetPopup;