import React, { createContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import './ConfirmModal.scss';


export const ConfirmContext = createContext();

export const ConfirmDialog = ({ children }) => {
    const [state, setState] = useState(undefined);

    const confirm = (message, btnA, btnB) => {
        return new Promise((resolve) => {
            setState({
                message: message,
                btnA: btnA,
                btnB: btnB,
                onClickOK: () => {
                    setState(undefined);
                    resolve(true);
                },
                onClickCancel: () => {
                    setState(undefined);
                    resolve(false);
                },
            });
        });
    };

    return (
        <ConfirmContext.Provider value={{confirm}}>
            {children}
            {state && (
                <Confirm
                message={state.message}
                btnA={state.btnA}
                btnB={state.btnB}
                onClickOK={state.onClickOK}
                onClickCancel={state.onClickCancel}
                />
            )}
        </ConfirmContext.Provider>
    );
};

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.24)',
        zIndex: 99
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '8px',
        boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
    },
};


Modal.setAppElement('#root');

const Confirm = ({message, btnA, btnB, onClickOK, onClickCancel}) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(()=>{
        if(message!=='') {
            setIsOpen(true);
        }
    },[message])
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClickCancel}
            style={customStyles}
        >
            <div className='modalContentArea confirmModal'>
                <p className='msg'>{message}</p>
                <div className='btnsArea'>
                    <button onClick={onClickOK}>{btnA}</button>
                    <button onClick={onClickCancel}>{btnB}</button>
                </div>
            </div>
        </Modal>
    );
};