import React from 'react';
import Modal from 'react-modal';
import './ChkDelModal.scss';
import closeIconImg from '../../images/closeIcon.png';

Modal.setAppElement('#root');

const customStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.24)',
        zIndex: 98,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '10px',
        boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
    },
};

function ChkDelModal({ isOpen, onRequestClose, onSubmit }) {
    return (
        <Modal
            isOpen={isOpen}
            style={customStyle}
            onRequestClose={onRequestClose}
        >
            <div className='modelContentArea checkDelete'>
            <button className='closeModal' onClick={onRequestClose}><img src={closeIconImg} alt={"창 닫기"} /></button>
                <div className='alertMsg'>
                    <p>삭제하시면 복구할 수 없습니다.</p>
                    <p>정말로 삭제하시겠습니까?</p>
                </div>
                <div className='btnsArea'>
                    <button className='deleteBtn' onClick={onSubmit}>삭제</button>
                    <button className='cancelBtn' onClick={onRequestClose}>취소</button>
                </div>
            </div>
        </Modal>
    );
}

export default ChkDelModal;