import React from 'react';
import Modal from 'react-modal';
import './LoginPageModal.scss';

Modal.setAppElement('#root');

const customStyle = {
    overlay: {
        backgroundColor: 'transparent'
        // zIndex: 98,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '10px',
        boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
    },
}

function DeniedUserModal({ isOpen, onRequestClose }) {
    return (
        <Modal
            isOpen={isOpen}
            style={customStyle}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={false}
        >
            <div className='loginPageModal deniedUser'>
                <div className='msg'>
                    <p>조직관리자의 승인이 거부되었습니다.<br />상세내용은 조직관리자에게 문의하여 주시기 바랍니다.</p>
                </div>
                <button className='goBackBtn' onClick={onRequestClose}>돌아가기</button>
            </div>
        </Modal>
    );
}

export default DeniedUserModal;