import React from 'react';
import { useParams } from 'react-router';
import logoImg from '../../images/logo.png';
import notVerifiedYetIcon from '../../images/notVerifiedYet.png';
import './AuthMailInfo.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../config/constants';

function NotVerifiedYet(props) {
    const param = useParams();
    const { id } = param;
    const onRequestResend = async (e) => {
        e.preventDefault();
        await axios.post(`${API_URL}/monitoring-manage/resendAuthMail`, { userEmail: id });
    };
    return (
        <div className='notVerifiedYet registerPanel'>
            <h1>이메일 인증</h1>
            <h2><img className='logoImg' src={logoImg} alt="로고" />공정 모니터링 시스템</h2>
            <div className='msgArea'>
                <div className='msg'>
                    <img className='notVerifiedYetIcon' src={notVerifiedYetIcon} alt="이메일 미인증" />
                    <p>아직 이메일이 인증되지 않았습니다.</p>
                </div>
                <div className='userEmail'>
                    <p>{id}</p>
                </div>
            </div>
            <div className='resendEmailArea'>
                <button className='resendEmail' onClick={onRequestResend}>인증메일 재전송</button>
                <p className='resendEmailMsg'>메일을 못 받으셨나요? 위 버튼을 클릭해주세요.</p>
            </div>
            <Link to={'/'} className='toLogIn'>로그인 화면으로</Link>
        </div>
    );
}

export default NotVerifiedYet;