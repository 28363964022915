import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

function CustomLineChart({ data }) {
    const aniDura = 500;
    const data1Arr = data.map(el => parseFloat(el.data1));
    const data2Arr = data.map(el => parseFloat(el.data2 ?? 0));
    const timeArr = data.map(el => parseFloat(el.time));
    const maxY = Math.ceil(Math.max(...data1Arr, ...data2Arr));
    const minY = Math.floor(Math.min(...data1Arr, ...data2Arr));
    // const maxDomain = Math.ceil(maxY / 5) * 5;
    // const minDomain = Math.floor(minY / 5) * 5;
    const tick = Math.ceil((maxY - minY) / 5);
    const maxDomain = Math.ceil(maxY / tick) * tick;
    const minDomain = Math.floor(minY / tick) * tick;
    const endTime = timeArr[timeArr.length - 1];
    let xTickArr = [0.0];
    let yTickArr = [];
    if (endTime < 0.5) {
        for (let i = 1; i < 5; i++) {
            xTickArr.push(parseFloat(0.1 * i));
        }
    } else {
        for (let i = 1; i < Math.ceil(endTime * 4); i++) {
            // console.log(i)
            xTickArr.push(parseFloat(0.25 * i));
        }
    }
    xTickArr.sort();
    for (let i = 0; i <= Math.ceil((maxDomain - minDomain) / tick); i++) {
        yTickArr.push(minDomain + tick * i);
    }
    const changedData = [...data, { time: Math.ceil(endTime * 2) / 2 }];
    return (
        <div className='chartArea'>
            <span className='axisTitle y'>치수 (mm)</span>
            <span className='axisTitle x'>시간 (초)</span>
            <ResponsiveContainer
                width={"100%"} height={"100%"}
            >
                <LineChart
                    data={changedData}

                >
                    <CartesianGrid
                        horizontal={true}
                        vertical={false}
                    />
                    <XAxis
                        dataKey={"time"}
                        tickLine={false}
                        height={30}
                        angle={30}
                        tickMargin={10}
                        // style={{fontSize: '20px'}}
                        // padding={{left: '10px', right: '10px', }}
                        allowDecimals={true}
                        tickFormatter={(xTick) => parseFloat(xTick).toFixed(2)}
                    // interval={0.1}
                    // domain={[0.0, 'Math.ceil(dataMax * 2) / 2']}
                    />
                    <YAxis
                        axisLine={false}
                        type='number'
                        tickLine={false}
                        ticks={yTickArr}
                        // tickCount={Math.ceil((maxY - minY) / tick)}
                        domain={[minDomain, maxDomain]}
                    // domain={[dataMin => (Math.floor(Math.floor(dataMin) / tick) * tick), dataMax => (Math.ceil(Math.ceil(dataMax) / tick) * tick)]}
                    />
                    <Line
                        type='monotone'
                        dataKey='data1'
                        stroke="#0A2B83"
                        dot={false}
                        animationDuration={aniDura}
                    // isAnimationActive={false}
                    />
                    <Line
                        type='monotone'
                        dataKey='data2'
                        stroke="#45BFCE"
                        dot={false}
                        animationDuration={aniDura}
                    // isAnimationActive={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default React.memo(CustomLineChart);