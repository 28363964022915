import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import './RefValueModal.scss';
import axios from 'axios';
import { API_URL } from '../../config/constants';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.24)',
        zIndex: 98
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '14px',
        boxShadow: '7px 7px 5px rgba(0, 0, 0, 0.35)'
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function RefValueModal({ isOpen, onRequestClose, refVal }) {
    const [formData, setFormData] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    function onChangeInput(e, idx, name) {
        e.preventDefault();
        const editedData = { ...formData[idx], [name]: parseFloat(e.target.value) };
        const copiedArr = [...formData];
        copiedArr[idx] = editedData;
        setIsChanged(true);
        setFormData(copiedArr);
    };

    async function onSubmit(e) {
        e.preventDefault();
        const refValue = formData.map(el => el.refValue).join(';');
        const lowErrRng = formData.map(el => el.lowErrRng).join(';');
        const highErrRng = formData.map(el => el.highErrRng).join(';');
        const reformedObj = {
            dataId: refVal?.dataId,
            dataStructureId: refVal?.dataStructureId,
            refValue, lowErrRng, highErrRng,
        };
        console.log(reformedObj);
        await axios.post(`${API_URL}/monitoring-manage/editRefValData`, reformedObj).then(res => {
            console.log(res.data.success);
            if (res.data.success) {
                setIsChanged(false);
                onRequestClose();
            }
        }).catch(err => console.error(err));
    };

    const printRows = useCallback(() => {
        return formData?.map((el, idx, arr) => {
            if (idx === 0) {
                return (
                    <tr key={idx}>
                        <td rowSpan={2} className='dsName'>{refVal?.dataStructure.name}</td>
                        <td className='dsDataName'>{el.dataName}</td>
                        <td className='rvRefValue'>
                            <input name="refValue" type="number" value={el.refValue || 0} onChange={e => onChangeInput(e, idx, e.target.name)} onBlur={e => { e.target.value = parseFloat(e.target.value) }} />
                        </td>
                        <td className='rvLowErrRng'>
                            <input name="lowErrRng" type="number" value={el.lowErrRng || 0} onChange={e => onChangeInput(e, idx, e.target.name)} onBlur={e => { e.target.value = parseFloat(e.target.value) }} onInput={(e) => {
                                if (e.target.value < 0) e.target.value = 0;
                            }} />
                        </td>
                        <td className='rvHighErrRng'>
                            <input name="highErrRng" type="number" value={el.highErrRng || 0} onChange={e => onChangeInput(e, idx, e.target.name)} onBlur={e => { e.target.value = parseFloat(e.target.value) }} onInput={(e) => {
                                if (e.target.value < 0) e.target.value = 0;
                            }} />
                        </td>
                    </tr>
                )
            } else {
                return (
                    <tr key={idx}>
                        <td className='dsDataName'>{el.dataName}</td>
                        <td className='rvRefValue'>
                            <input name="refValue" type="number" value={el.refValue || 0} onChange={e => onChangeInput(e, idx, e.target.name)} onBlur={e => { e.target.value = parseFloat(e.target.value) }} />
                        </td>
                        <td className='rvLowErrRng'>
                            <input name="lowErrRng" type="number" value={el.lowErrRng || 0} onChange={e => onChangeInput(e, idx, e.target.name)} onBlur={e => { e.target.value = parseFloat(e.target.value) }} onInput={(e) => {
                                if (e.target.value < 0) e.target.value = 0;
                            }} />
                        </td>
                        <td className='rvHighErrRng'>
                            <input name="highErrRng" type="number" value={el.highErrRng || 0} onChange={e => onChangeInput(e, idx, e.target.name)} onBlur={e => { e.target.value = parseFloat(e.target.value) }} onInput={(e) => {
                                if (e.target.value < 0) e.target.value = 0;
                            }} />
                        </td>
                    </tr>
                )
            }
        })
    }, [formData])

    useEffect(() => {
        const dataName = refVal?.dataStructure.dataName.split(';');
        const refValue = refVal?.refValue.split(';');
        const lowErrRng = refVal?.lowErrRng.split(';');
        const highErrRng = refVal?.highErrRng.split(';');
        const rows = dataName?.map((item, idx) => ({
            dataName: item,
            refValue: parseFloat(refValue[idx]),
            lowErrRng: parseFloat(lowErrRng[idx]),
            highErrRng: parseFloat(highErrRng[idx]),
        }));
        setFormData(rows);
    }, [refVal]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel='기준값 관리'
        >
            <div className='modalContentArea refValSettings'>
                <h1>기준값 관리</h1>
                <form onSubmit={onSubmit}>
                    <div className='hintBtn'>
                        ?
                        <div className='hintMsg'><p>셀을 직접 클릭하여 수정이 가능합니다.</p></div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th className='dsName' rowSpan={2}><div>name</div></th>
                                <th className='dsDataName' rowSpan={2}><div>dataName</div></th>
                                <th className='rvRefValue' rowSpan={2}><div>기준값</div></th>
                                <th className='rvErrRng' colSpan={2}><div>오차</div></th>
                            </tr>
                            <tr>
                                <th className='secondRowTh'>하한</th>
                                <th className='secondRowTh'>상한</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                printRows()
                            }
                        </tbody>
                    </table>
                    <div className='btnsArea'>
                        <input className='formBtn' type='submit' value={'확인'} disabled={!isChanged} />
                        <button className='formBtn' onClick={e => {
                            setIsChanged(false);
                            onRequestClose(e);
                        }}>취소</button>
                    </div>
                </form>

            </div>

        </Modal>
    );
}

export default RefValueModal;