import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const customStyle = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.24)',
        // backgroundColor: 'transparent'
        // zIndex: 98,
    },
    content: {
        top: '18px',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translateX(-50%)',
        padding: 'none',
        borderRadius: '10px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
    },
}

function RegisteredEmailModal({isOpen, onRequestClose}) {
    return (
        <Modal
            isOpen={isOpen}
            style={customStyle}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={true}
        >
            <div className='loginPageModal registeredEmail'>
                <div className='msg'>
                    <p>중복된 이메일을 사용한 계정이 있습니다.<br />내 정보 페이지에서<br />소셜 계정을 연동해주세요.</p>
                </div>
                <button className='goBackBtn' onClick={onRequestClose}>확인</button>
            </div>
        </Modal>
    );
}

export default RegisteredEmailModal;