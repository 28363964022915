import React, { useEffect, useRef, useState } from 'react';
import prevMonIconImg from '../../images/prevMonIcon.png';
import nextMonIconImg from '../../images/nextMonIcon.png';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import moment from 'moment';
import 'moment/locale/ko';
import arrowIconImg from '../../images/datePickerDropdownIcon.png';
import incIconImg from '../../images/incIcon.png';
import decIconImg from '../../images/decIcon.png';
import './CustomDatePicker.scss';
import Select from 'react-select';

registerLocale('ko', ko);

const CustomHeader = ({
    date,
    changeMonth,
    changeYear,
    decreaseMonth,
    increaseMonth,
}) => {
    const currYear = moment().year();
    const monthDates = [];
    for (let i = 0; i < 5; i++) {
        if (i === 0) {
            for (let j = moment().month(); j >= 0; j--) {
                const dateData = moment([(currYear - i), j]).format('yyyy-M');
                monthDates.push(dateData.toString());
            }
        } else {
            for (let j = 12 - 1; j >= 0; j--) {
                const dateData = moment([(currYear - i), j]).format('yyyy-M');
                monthDates.push(dateData.toString());
            }
        }
    }

    const onChange = e => {
        e.preventDefault();
        changeMonth(moment(e.target.value).month());
        changeYear(moment(e.target.value).year());
        console.log(moment(e.target.value).month())
    }

    return (
        <div className='customHeader'>
            <button
                aria-label="Previous Month"
                className={"navigation prevBtn"}
                onClick={decreaseMonth}
                disabled={moment(date).format('yyyy-M') <= moment([(currYear - 4), 0]).format('yyyy-M')}
            >
                <img src={prevMonIconImg} alt={"이전 달"} />
            </button>
            <div className='selectBox'>
                {/* <Select
                options={monthDates.map(el => ({label: moment(el).format("yyyy - M월"), value: el}))}
                onChange={}
                value={}
                classNames={{
                    control: (state) => 'control',
                    container: (state) => 'container',
                    dropdownIndicator: (state) => 'arrow',
                    menu: (state) => 'options',
                  }}
                  components={{
                    DropdownIndicator: (state) => <div style={{ backgroundColor: 'red', width: '20px', height: '100px', border: 'none' }}>
                      <p>{state.isFocused ? '포커스':'언포'}</p>
                    </div>,
                    IndicatorSeparator: (state) => null,
                  }}
                /> */}
                <select onChange={onChange} value={moment(date).format('yyyy-M')}>
                    {monthDates.map((item, idx) => {
                        return (
                            <option key={idx} value={item}>{moment(item).format("yyyy - M월")}</option>
                        );
                    })}
                </select>
                <span className='arrowIcon'><img src={arrowIconImg} alt={"드롭다운"} /></span>
            </div>
            <button
                aria-label="Next Month"
                className={"navigation nextBtn"}
                onClick={increaseMonth}
                disabled={moment(date).format('yyyy-M') >= moment().format('yyyy-M')}
            >
                <img src={nextMonIconImg} alt={"다음 달"} />
            </button>
        </div>
    )
};

export function CustomDatePickerStart({ startDate, setStartDate, endDate, changeDropdown }) {
    const [isOpen, setIsOpen] = useState(false);
    const CustomTimeInput = ({ date, value, onTimeChange, onChange }) => {
        const startDateH = startDate.getHours();
        const startDateM = startDate.getMinutes();
        const [hour, setHour] = useState(startDateH < 10 ? `0${parseInt(startDateH)}` : startDateH);
        const [minute, setMinute] = useState(startDateM < 10 ? `0${parseInt(startDateM)}` : startDateM);
        function onClickBtn(e) {
            e.preventDefault();
            const btnName = e.target.name;
            switch (btnName) {
                case 'hour':
                    let hou;
                    if (e.target.id === 'hourInc') {
                        hou = parseInt(hour) >= 23 ? 0 : parseInt(hour) + 1;
                        setHour(hou);
                    } else {
                        hou = parseInt(hour) <= 0 ? 23 : parseInt(hour) - 1;
                        setHour(hou);
                    }
                    setStartDate(new Date(startDate.setHours(hou)));
                    break;
                case 'minute':
                    let min;
                    if (e.target.id === 'minuteInc') {
                        min = parseInt(minute) >= 59 ? 0 : parseInt(minute) + 1;
                        setMinute(min);
                    } else {
                        min = parseInt(minute) <= 0 ? 59 : parseInt(minute) - 1;
                        setMinute(min);
                    }
                    setStartDate(new Date(startDate.setMinutes(min)));
                    break;
                default:
                    break;
            }
        };
        return (
            <div className='timeNSubmit'>
                <div className='timeInputArea'>
                    <div className='hourInputArea inputArea'>
                        <input className='hourInput timeInput' type='number' min={-1} max={24} value={hour} onChange={e => {
                            let val;
                            if (parseInt(e.target.value) <= -1) {
                                e.target.value = 23;
                                val = 23;
                            } else if (e.target.value >= 24) {
                                e.target.value = 0;
                                val = 0;
                            } else {
                                val = e.target.value;
                            }
                            setHour(parseInt(val) < 10 ? `0${parseInt(val)}` : val);
                            setStartDate(new Date(startDate.setHours(val)));
                        }} />
                        <div className='btnsArea'>
                            <button name='hour' id='hourInc' onClick={onClickBtn}><img src={incIconImg} alt='시간-위' /></button>
                            <button name='hour' id='hourDec' onClick={onClickBtn}><img src={decIconImg} alt='시간-아래' /></button>
                        </div>
                    </div>
                    <span>:</span>
                    <div className='minuteInputArea inputArea'>
                        <input className='minuteInput timeInput' type='number' min={-1} max={60} value={minute} onChange={e => {
                            e.preventDefault();
                            let val;
                            console.log(typeof e.target.value)
                            if (parseInt(e.target.value) <= -1) {
                                e.target.value = 59;
                                val = 59;
                            } else if (e.target.value >= 60) {
                                e.target.value = 0;
                                val = 0;
                            } else {
                                val = e.target.value;
                            }
                            setMinute(parseInt(val) < 10 ? `0${parseInt(val)}` : val);
                            setStartDate(new Date(startDate.setMinutes(val)));
                        }} />
                        <div className='btnsArea'>
                            <button name='minute' id='minuteInc' onClick={onClickBtn}><img src={incIconImg} alt='분-위' /></button>
                            <button name='minute' id='minuteDec' onClick={onClickBtn}><img src={decIconImg} alt='분-아래' /></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
        return (
            <React.Fragment>
                <input type='text' onClick={e => {
                    onClick(e);
                    changeDropdown();
                    setIsOpen(true);
                }} value={moment(startDate).format('yyyy-MM-DD (dd) H:mm').toLocaleString('Ko')} ref={ref} readOnly />
                <input type='text' value={value} hidden={true} readOnly />
            </React.Fragment>
        );
    });

    return (
        <DatePicker
            open={isOpen}
            onClickOutside={() => { setIsOpen(false) }}
            selected={startDate}
            value={startDate}
            onChange={(date) => setStartDate(date)}
            shouldCloseOnSelect={false}
            locale={'ko'}
            showTimeInput
            timeInputLabel=''
            renderCustomHeader={CustomHeader}
            customTimeInput={<CustomTimeInput />}
            dateFormat={'yyyy-MM-dd (E) H:mm'}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            customInput={<CustomInput />}
            minDate={new Date(moment([(moment().year() - 4), 0, 1]))}
            maxDate={endDate}
        />
    );
}

export function CustomDatePickerEnd({ startDate, endDate, setEndDate, changeDropdown }) {
    const [isOpen, setIsOpen] = useState(false);

    const CustomTimeInput = ({ date, value, onTimeChange, onChange }) => {
        const endDateH = endDate.getHours();
        const endDateM = endDate.getMinutes();
        const [hour, setHour] = useState(endDateH < 10 ? `0${parseInt(endDateH)}` : endDateH);
        const [minute, setMinute] = useState(endDateM < 10 ? `0${parseInt(endDateM)}` : endDateM);

        function onClickBtn(e) {
            e.preventDefault();
            const btnName = e.target.name;
            switch (btnName) {
                case 'hour':
                    let hou;
                    if (e.target.id === 'hourInc') {
                        hou = parseInt(hour) >= 23 ? 0 : parseInt(hour) + 1;
                        setHour(hou);
                    } else {
                        hou = parseInt(hour) <= 0 ? 23 : parseInt(hour) - 1;
                        setHour(hou);
                    }
                    setEndDate(new Date(endDate.setHours(hou)));
                    break;
                case 'minute':
                    let min;
                    if (e.target.id === 'minuteInc') {
                        min = parseInt(minute) >= 59 ? 0 : parseInt(minute) + 1;
                        setMinute(min);
                    } else {
                        min = parseInt(minute) <= 0 ? 59 : parseInt(minute) - 1;
                        setMinute(min);
                    }
                    setEndDate(new Date(endDate.setMinutes(min)));
                    break;
                default:
                    break;
            }
        };
        return (
            <div className='timeNSubmit'>
                <div className='timeInputArea'>
                    <div className='hourInputArea inputArea'>
                        <input className='hourInput timeInput' type='number' min={-1} max={24} value={hour} onChange={e => {
                            let val;
                            if (parseInt(e.target.value) <= -1) {
                                e.target.value = 23;
                                val = 23;
                            } else if (e.target.value >= 24) {
                                e.target.value = 0;
                                val = 0;
                            } else {
                                val = e.target.value;
                            }
                            setHour(parseInt(val) < 10 ? `0${parseInt(val)}` : val);
                            setEndDate(new Date(endDate.setHours(val)));
                        }} />
                        <div className='btnsArea'>
                            <button name='hour' id='hourInc' onClick={onClickBtn}><img src={incIconImg} alt='시간-위' /></button>
                            <button name='hour' id='hourDec' onClick={onClickBtn}><img src={decIconImg} alt='시간-아래' /></button>
                        </div>
                    </div>
                    <span>:</span>
                    <div className='minuteInputArea inputArea'>
                        <input className='minuteInput timeInput' type='number' min={-1} max={60} value={minute} onChange={e => {
                            e.preventDefault();
                            let val;
                            console.log(typeof e.target.value)
                            if (parseInt(e.target.value) <= -1) {
                                e.target.value = 59;
                                val = 59;
                            } else if (e.target.value >= 60) {
                                e.target.value = 0;
                                val = 0;
                            } else {
                                val = e.target.value;
                            }
                            setMinute(parseInt(val) < 10 ? `0${parseInt(val)}` : val);
                            setEndDate(new Date(endDate.setMinutes(val)));
                        }} />
                        <div className='btnsArea'>
                            <button name='minute' id='minuteInc' onClick={onClickBtn}><img src={incIconImg} alt='분-위' /></button>
                            <button name='minute' id='minuteDec' onClick={onClickBtn}><img src={decIconImg} alt='분-아래' /></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
        return (
            <React.Fragment>
                <input type='text' onClick={e => {
                    onClick(e);
                    changeDropdown();
                    setIsOpen(true);
                }} value={moment(endDate).format('yyyy-MM-DD (dd) H:mm').toLocaleString('Ko')} ref={ref} readOnly />
                <input type='text' value={value} hidden={true} readOnly />
            </React.Fragment>
        );
    });
    return (
        <DatePicker
            open={isOpen}
            onClickOutside={() => { setIsOpen(false) }}
            selected={endDate}
            value={endDate}
            onChange={(date) => setEndDate(date)}
            shouldCloseOnSelect={false}
            locale={'ko'}
            showTimeInput
            timeInputLabel=''
            renderCustomHeader={CustomHeader}
            customTimeInput={<CustomTimeInput />}
            dateFormat={'yyyy-MM-dd (E) H:mm'}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            customInput={<CustomInput />}
            minDate={startDate}
            maxDate={new Date()}
        />
    );
}

export function CustomDatePicker({ data, setData, isDisabled = false }) {
    const [isOpen, setIsOpen] = useState(false);

    const CustomTimeInput = ({ date, value, onTimeChange, onChange }) => {
        const dataH = data.getHours();
        const dataM = data.getMinutes();
        const dataS = data.getSeconds();
        const [hour, setHour] = useState(dataH < 10 ? `0${parseInt(dataH)}` : dataH);
        const [minute, setMinute] = useState(dataM < 10 ? `0${parseInt(dataM)}` : dataM);
        const [second, setSecond] = useState(dataS < 10 ? `0${parseInt(dataS)}` : dataS);

        function onClickBtn(e) {
            e.preventDefault();
            const btnName = e.target.name;
            switch (btnName) {
                case 'hour':
                    let hou;
                    if (e.target.id === 'hourInc') {
                        hou = parseInt(hour) >= 23 ? 0 : parseInt(hour) + 1;
                        setHour(hou);
                    } else {
                        hou = parseInt(hour) <= 0 ? 23 : parseInt(hour) - 1;
                        setHour(hou);
                    }
                    setData(new Date(data.setHours(hou)));
                    break;
                case 'minute':
                    let min;
                    if (e.target.id === 'minuteInc') {
                        min = parseInt(minute) >= 59 ? 0 : parseInt(minute) + 1;
                        setMinute(min);
                    } else {
                        min = parseInt(minute) <= 0 ? 59 : parseInt(minute) - 1;
                        setMinute(min);
                    }
                    setData(new Date(data.setMinutes(min)));
                    break;
                case 'second':
                    let sec;
                    if (e.target.id === 'secondInc') {
                        sec = parseInt(second) >= 59 ? 0 : parseInt(second) + 1;
                        setSecond(sec);
                    } else {
                        sec = parseInt(second) <= 0 ? 59 : parseInt(second) - 1;
                        setSecond(sec);
                    }
                    setData(new Date(data.setSeconds(sec)));
                    break;
                default:
                    break;
            }
        };
        return (
            <div className='timeNSubmit'>
                <div className='timeInputArea'>
                    <div className='hourInputArea inputArea'>
                        <input className='hourInput timeInput' type='number' min={-1} max={24} value={hour} onChange={e => {
                            let val;
                            if (parseInt(e.target.value) <= -1) {
                                e.target.value = 23;
                                val = 23;
                            } else if (e.target.value >= 24) {
                                e.target.value = 0;
                                val = 0;
                            } else {
                                val = e.target.value;
                            }
                            setHour(parseInt(val) < 10 ? `0${parseInt(val)}` : val);
                            setData(new Date(data.setHours(val)));
                        }} />
                        <div className='btnsArea'>
                            <button name='hour' id='hourInc' onClick={onClickBtn}><img src={incIconImg} alt='시간-위' /></button>
                            <button name='hour' id='hourDec' onClick={onClickBtn}><img src={decIconImg} alt='시간-아래' /></button>
                        </div>
                    </div>
                    <span>:</span>
                    <div className='minuteInputArea inputArea'>
                        <input className='minuteInput timeInput' type='number' min={-1} max={60} value={minute} onChange={e => {
                            e.preventDefault();
                            let val;
                            console.log(typeof e.target.value)
                            if (parseInt(e.target.value) <= -1) {
                                e.target.value = 59;
                                val = 59;
                            } else if (e.target.value >= 60) {
                                e.target.value = 0;
                                val = 0;
                            } else {
                                val = e.target.value;
                            }
                            setMinute(parseInt(val) < 10 ? `0${parseInt(val)}` : val);
                            setData(new Date(data.setMinutes(val)));
                        }} />
                        <div className='btnsArea'>
                            <button name='minute' id='minuteInc' onClick={onClickBtn}><img src={incIconImg} alt='분-위' /></button>
                            <button name='minute' id='minuteDec' onClick={onClickBtn}><img src={decIconImg} alt='분-아래' /></button>
                        </div>
                    </div>
                    <span>:</span>
                    <div className='secondInputArea inputArea'>
                        <input className='secondInput timeInput' type='number' min={-1} max={60} value={second} onChange={e => {
                            e.preventDefault();
                            let val;
                            console.log(typeof e.target.value)
                            if (parseInt(e.target.value) <= -1) {
                                e.target.value = 59;
                                val = 59;
                            } else if (e.target.value >= 60) {
                                e.target.value = 0;
                                val = 0;
                            } else {
                                val = e.target.value;
                            }
                            setSecond(parseInt(val) < 10 ? `0${parseInt(val)}` : val);
                            setData(new Date(data.setSeconds(val)));
                        }} />
                        <div className='btnsArea'>
                            <button name='second' id='secondInc' onClick={onClickBtn}><img src={incIconImg} alt='초-위' /></button>
                            <button name='second' id='secondDec' onClick={onClickBtn}><img src={decIconImg} alt='초-아래' /></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
        return (
            <React.Fragment>
                <input type='text' onClick={e => {
                    if (!isDisabled) {
                        onClick(e);
                        setIsOpen(true);
                    }
                }} value={moment(data).format('yyyy-MM-DD H:mm:ss').toLocaleString('Ko')} ref={ref} readOnly />
                <input type='text' value={value} hidden={true} readOnly />
            </React.Fragment>
        );
    });
    return (
        <div className='customDatePickerArea'>
            <DatePicker
                open={isOpen}
                onClickOutside={() => { setIsOpen(false) }}
                selected={data}
                value={data}
                onChange={(date) => setData(date)}
                shouldCloseOnSelect={false}
                locale={'ko'}
                showTimeInput
                timeInputLabel=''
                renderCustomHeader={CustomHeader}
                customTimeInput={<CustomTimeInput />}
                dateFormat={'yyyy-MM-dd (E) H:mm:ss'}
                selectsEnd
                customInput={<CustomInput />}
                minDate={new Date(moment([(moment().year() - 4), 0, 1]))}
                maxDate={new Date()}
            />
        </div>
    );
}