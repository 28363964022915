import React from 'react';
import Modal from 'react-modal';
import './LoginPageModal.scss';

Modal.setAppElement('#root');

const customStyle = {
    overlay: {
        backgroundColor: 'transparent'
        // zIndex: 98,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 'none',
        borderRadius: '10px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
    },
}

function WrongPwModal({isOpen, onRequestClose, failedCount}) {
    return (
        <Modal
            isOpen={isOpen}
            style={customStyle}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={false}
        >
            <div className='loginPageModal notApprovedYet'>
                <div className='msg'>
                    <p>{`비밀번호를 ${failedCount}회 틀리셨습니다.`}</p>
                    <p>5회 이상 틀릴 시 새로운 비밀번호를 설정해야 합니다.</p>
                </div>
                <button className='goBackBtn' onClick={onRequestClose}>돌아가기</button>
            </div>
        </Modal>
    );
}

export default WrongPwModal;