import React, { useEffect, useState } from 'react';
import ShowModels from './ShowModels';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import './model.scss';
import { useNavigate, useOutletContext } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../../reducer/authentication';

function Model(props) {
    const { userIsNormal } = useOutletContext();
    const dispatch = useDispatch();
    const onLogout = () => dispatch(setLogout());
    const [modelList, setModelList] = useState([]);
    async function getModels() {
        const response = await axios.get(`${API_URL}/monitoring-manage/getModels`);
        setModelList([...response.data]);
    }
    useEffect(() => {
        if (userIsNormal) onLogout();
    }, [userIsNormal]);
    useEffect(() => {
        getModels();
    }, [])
    return (
        <React.Fragment>
            <ShowModels models={modelList} />
        </React.Fragment>
    );
}

export default Model;