import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_URL } from '../../config/constants';
import './ChangePw.scss';

function ChangePw(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const userEmail = searchParams.get('email');
    const token = searchParams.get('token');
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        userPw: '',
        userPwChk: '',
    });
    const { userPw, userPwChk } = formData;
    const [errorOccurred, setErrorOccurred] = useState({
        userPwError: false,
        userPwChkError: false,
    });
    const { userPwError, userPwChkError } = errorOccurred;

    async function getAuth() {
        await axios.post(`${API_URL}/monitoring-manage/getAuthForChgPw`, { userEmail, token }).then(res => {
            const { success } = res.data;
            if (success) {
                setShowForm(true);
            }
        }).catch(err => console.error(err))
    }

    function onChange(e) {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    }

    async function onSubmit(e) {
        e.preventDefault();
        const errorExist = !Object.values(errorOccurred).every(x => x === false);
        if (errorExist) return;
        else if (userPw.length === 0) { setErrorOccurred({ ...errorOccurred, userPwError: true }); }
        else if (userPw !== userPwChk) { setErrorOccurred({ ...errorOccurred, userPwChkError: true }); }
        else {
            await axios.post(`${API_URL}/monitoring-manage/changePwByEmailAuth`, { userEmail, userPw: userPw }).then(res => {
                const { success } = res.data;
                if (success) {
                    navigate('/');
                }
            }).catch(err => console.error(err));
        };
    };

    const onBlurPw = e => {
        // 비밀번호 형식 정규식
        // 비밀번호는 영문, 숫자, 특수문자를 모두 포함하고, 공백이 없는 8~15자
        let reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
        // 공백 체크
        const pwHasSpace = e.target.value.indexOf(" ") !== -1;
        const hasProblems = !(reg.test(e.target.value) && !pwHasSpace)

        setErrorOccurred({ ...errorOccurred, userPwError: hasProblems });
    };

    const onBlurPwChk = e => {
        if (userPwError) return;
        setErrorOccurred({ ...errorOccurred, userPwChkError: formData.userPw !== formData.userPwChk });
    };

    useEffect(() => {
        getAuth();
    }, [])
    return (
        <div>
            {
                showForm ?
                    <form className='changePwForm' onChange={onChange} onSubmit={onSubmit}>
                        <div className='inputsArea'>
                            <div className={userPwError ? 'inputArea error' : 'inputArea'}>
                                <input type='password' name='userPw' placeholder='비밀번호' autoComplete="new-password" onBlur={onBlurPw} defaultValue={formData.userPw} />
                                <span className={!userPwError ? 'pwFormatSpan' : 'errMsgSpan'}>비밀번호는 영문, 숫자, 특수문자를 모두 포함하여 공백없이 8~15자로 구성되어야 합니다.</span>
                            </div>
                            <div className={userPwChkError ? 'inputArea error' : 'inputArea'}>
                                <input type='password' name='userPwChk' placeholder='비밀번호 확인' autoComplete="new-password" onBlur={onBlurPwChk} defaultValue={formData.userPwChk} />
                                <span className={'errMsgSpan pwChkError'}>비밀번호가 일치하지 않습니다.</span>
                            </div>
                        </div>
                        <div className='btnsArea'>
                            <input type='submit' value={'제출'} />
                        </div>
                    </form>
                    : null
            }
        </div>
    );
}

export default ChangePw;